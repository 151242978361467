import { formatNumero, roundDecimals } from '@/addons/adjustments'
import { isConfigTruthy } from '@/addons/functions/configs'
import {
  catalogApi,
  consumerDataApi,
  couponsApi,
  giftCardApi,
  salesApi,
} from '@/addons/axios'
import {
  AnonymousConsumers,
  CodiceStatoVendita,
  LaybyCartTypeID,
  LaybyStatusType,
  PaymentKeys,
  PageContexts,
  SaleStoreStatus,
  SessionKeys,
  StockIcon,
  TipiDocumento,
  TipiMovimenti,
  TipiVendita,
  ShipDestinationType,
} from '@/addons/enums'
import i18n from '@/addons/i18n'
import {
  ApiV1CouponsCouponCodeCheckDataAttributes,
  ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdDataAttributesTipoStornoEnum as TipoStornoEnum,
  ApiV1SalesCheckDatiAntiriciclaggioDataAttributes,
  ApiV1SalesData,
  ApiV1SalesDataAttributes,
  ApiV1SalesDataAttributesCapi,
  ApiV1SalesDataAttributesPagamenti,
  ApiV1SalesSaleIdDataAttributes,
  ApiV1SalesSaleIdDataAttributesCapi,
  ApiV1SalesSaleIdDataAttributesPagamenti,
  BurnGiftCard,
  CouponData,
  GetCashiersAttributes,
  GetConsumerAttributes,
  GetConsumerExtraInfoDataAttributes,
  GetCountriesAttributes,
  GetSkuDataAttributes,
  GetSkuDataAttributesListiniPriceType,
  GiftCardData,
  GiftCardData1DataAttributes,
  GiftCardInformationDataAttributes,
  GiftCards,
  InlineResponse200,
  InlineResponse2001,
  ManageSaleReturnData,
  PatchSale,
  PostConsumerResponseDataAttributes,
  PostCoupon1,
  PostSale,
  ReturnBurnGiftCard,
  Sale,
} from '@/api'
import modalController, { ModalTypes } from '@/components/modal/ModalController'
import {
  FatturaAmbForm,
  FatturaForm,
  FatturaTaxfreeForm,
  FatturaUEForm,
} from '@/components/recap/fattura/fatturaModels'
import {
  AlterationsTab,
  AlterationsTabItem,
  GetAlterationPricesDataAttributes,
} from '@/interfaces/tailoring'
import router from '@/router'
import { ConfigGetters } from '@/store/configs-store'
import { GenericActions, GenericGetters } from '@/store/generic-store'
import { LoyaltyGetters, LoyaltyActions } from '@/modules/loyalty/store/index'
import { AxiosError, AxiosResponse } from 'axios'
import { addMinutes, compareAsc, format, parse, subDays } from 'date-fns'
import { ActionContext, GetterTree } from 'vuex'
import store, { RootState } from '../index'
import { NotificationsActions } from '../notifications-store'
import { extractErrorMessage, getNewTransactionId } from '@/addons/functions'
import LoaderController from '@/components/loader/LoaderController'
import {
  CostiExtraType,
  PaymentsMutations,
  PaymentsType,
} from '../payments-store'
import { AuthGetters } from '@/store/auth'
import { GiftCardForm } from '@/interfaces/gift-card'
import { SaleTicketDocument } from '@/interfaces/recap-documents'
import { SuspendedCreditForm } from '@/components/sales/payments/payment-suspended-credit/suspendedCredit'
import { LaybyElement, LaybyElementAttr } from '@/store/layby-store'
import {
  dateToISODayWithTime,
  unformatMMFGDate,
  ISO8601DateTimeStandard,
} from '@/addons/date'
import {
  CashGenericPaymentMethodForm,
  ChequeGenericPaymentMethodForm,
  PaymentMethodForm,
} from '@/interfaces/payment-method-form'
import { stockIcon } from '@/addons/functions/sales'
import PrintErrorHandler, {
  ErrorDisplayTypes,
} from '@/addons/functions/printErrorHandler'
import { GiftMutations } from '../gift-store'
import { ConsumersActions, ConsumersGetters } from '@/store/consumer'
import { ErrorD } from '@/addons/interface'
import { openModalTailoringAlert } from '@/components/gestione-tessuto/modal'
import { ABSTRID_EA } from '@/configs'
import {
  AddressValidationActions,
  AddressValidationGetters,
} from '@/modules/address-validation/store'
export interface UsedCash {
  amount?: number
  payable_amount?: number
}

export interface CanChangeCartTypeD {
  value: boolean
  message: string
}

export interface DiscountData {
  data: string
  discountActive: boolean
}

export interface InfoGiacenzaCapiD {
  sku: string
  quantity: number
  stock: number
  is_promo: boolean
  giac_offline: boolean
  stock_type?: string
}

type modalCreditCardInstanceType = {
  confirm(): void
  close(): void
}
export interface SalesState {
  currentSale: ApiV1SalesDataAttributes | ApiV1SalesSaleIdDataAttributes
  progressivo_capi: number
  product_detail: { [key: string]: GetSkuDataAttributes }
  progressivo_pagamenti: number
  consumer?: GetConsumerAttributes
  isGroupConsumer: boolean
  isAnonymous: boolean
  selected_cashier?: GetCashiersAttributes
  selected_rdv?: GetCashiersAttributes
  skuAlertOpen?: boolean
  cartView?: string
  consumerStatistics?: Statistics
  suspendedCredit: SuspendedCreditForm
  headerIcons: GetConsumerExtraInfoDataAttributes
  paymentData: PaymentMethodForm
  cartType: CartType[]
  maxImportCheque: string
  selectedCartType: CartType
  laybyInfo: LaybyInfo
  laybyStatus: LaybyStatusType
  totalPriceDeposit: number
  totalPriceDepositPaymentMethod: number
  laybyConsumerList: LaybyConsumerList[]
  laybyResumeData: LaybyElement
  laybyDepositValid: boolean
  saveGiftCardInProcess: boolean
  usedCash: UsedCash
  modalCreditCardInstance: modalCreditCardInstanceType | undefined
  dati_storno: datiStornoD
  disabledConfirm: boolean
  canChangeCartType: CanChangeCartTypeD
  checkAntireciclaggioResult: boolean
  pk_consumer: string
  infoGiacenzaCapi: IntoGiacenzaCapiT
}

export type IntoGiacenzaCapiT = {
  [sku: string]: InfoGiacenzaCapiD
}
export interface datiStornoD {
  num_bollettina: string
  data_bollettina: string
  possiede_scontrino: 0 | 1
  original_sale_type: 'SALE' | 'SALE_B2E'
}

export interface LaybyInfo {
  totale: number
  acconto_minimo: number
  importo_oltre_acconto_minimo: number
  importo_mancante: number
  importo_rendibile: number
  importo_trattenuto: number
  prossimo_pagamento: number
  amountForPayment: number
  cancellato: number
  importo_pagato: number
  importo_pagamento: number
  [k: string]: number
}

export interface CartType {
  id?: LaybyCartTypeID
  value?: string
  label?: string
}

export interface ShippingDetails {
  shipping_destination: string
  shipping_address?: ShippingAddress
}

export interface Statistics {
  statistics: InlineResponse200
  statisticsInsegna: InlineResponse2001
}

interface CapiLaybyDelete extends ApiV1SalesDataAttributesCapi {
  layby_delete?: boolean
}

export interface ShippingAddress {
  surname: string
  name: string
  address: string
  street_number?: string
  building?: string
  city: string
  zipcode: string
  country?: string
  province?: string
  state?: string
  contact_note?: string
  contact_email?: string
  contact_number?: string
  address_notes?: string
  consumer_info?: {
    surname: string
    name: string
    reference_name: string
    contact_number: string
    contact_email: string
  }
  reference_name?: string
}

interface StoreConsumerPayload {
  consumer: null | GetConsumerAttributes | PostConsumerResponseDataAttributes
  groupConsumer?: boolean
  noAlert?: boolean
}

interface LaybyConsumerList {
  balance?: number
  cod_negozio?: string
  consumatrice?: string
  data_documento?: string
  expiring_date?: string
  id_transazione?: number
  importo_totale?: number
  layby_acconto_minimo?: number
  layby_importo_mancante?: number
  layby_importo_oltre_acconto_minimo?: number
  layby_importo_pagato?: number
  layby_importo_rendibile?: number
  layby_importo_trattenuto?: number
  money_return_limit_date?: string
  next_payment_date?: number
  numero_capi?: number
  numero_documento?: number
  numero_documento_orig?: string
  required_amount?: number
  total_paid_amount?: number
}

enum Mutations {
  SET_MODAL_CREDIT_CARD_INSTANCE = 'setModalCrediCardInstance',
  RESET_STATE = 'reset-state',
  UPDATE_SALE_DATA = 'update-sale-data',
  STORE_SALE = 'sale-store',
  CLEAR_SALE = 'sale-clear',
  STORE_SALE_IMPORTO_FINALE = 'sale-store-importo-finale',
  CLEAR_SALE_IMPORTO_FINALE = 'sale-clear-importo-finale',
  STORE_SALE_IMPORTO_PAGATO = 'sale-store-importo-pagato',
  CLEAR_SALE_IMPORTO_PAGATO = 'sale-clear-importo-',
  STORE_SALE_CAPI = 'sale-store-capi',
  CLEAR_SALE_CAPI = 'sale-clear-capi',
  STORE_SALE_PAGAMENTI = 'sale-store-pagamenti',
  CLEAR_SALE_PAGAMENTI = 'sale-clear-pagamenti',
  CLEAR_ONLY_PAYMENTS_TYPE = 'sale-clear-only-payments-type',
  STORE_PRODUCTS = 'sale-products-store',
  CLEAR_PRODUCTS = 'sale-products-clear',
  STORE_PROGRESSIVO_CAPO = 'sale-progressivo-capo-store',
  CLEAR_PROGRESSIVO_CAPO = 'sale-progressivo-capo-clear',
  STORE_PROGRESSIVO_PAGAMENTI = 'sale-progressivo-pagamenti-store',
  CLEAR_PROGRESSIVO_PAGAMENTI = 'sale-progressivo-pagamenti-clear',
  STORE_CONSUMER = 'sale-consumer-store',
  CLEAR_CONSUMER = 'sale-consumer-clear',
  STORE_ANONYMOUS_FLAG = 'sale-anonymous-flag-store',
  CLEAR_ANONYMOUS_FLAG = 'sale-anonymous-flag-clear',
  STORE_RDV = 'sale-rdv-store',
  CLEAR_RDV = 'sale-rdv-clear',
  STORE_CASHIER = 'sale-cashier-store',
  CLEAR_CASHIER = 'sale-cashier-clear',
  UPDATE_PRODUCT_DETAIL = 'sales-updateProductDetail',
  STORE_SKU_ALERT_OPEN = 'sales-store-sku-alert-open',
  STORE_CREATION_DATE = 'sales-store-creation-date',
  UPDATE_SALE_STATUS = 'sales-update-sale-status',
  STORE_ID_TRANSAZIONE = 'sales-store-id-transazione',
  CLEAR_ID_TRANSAZIONE = 'sales-clear-id-transazione',
  STORE_CART_VIEW = 'sales-store-cart-view',
  SET_GIFT_CARD_SAVE_IN_PROCESS = 'set-gift-card-save-in-process',
  STORE_DATA_VISIBILITY = 'sales-store-data-visibility',
  UPDATE_SUSPENDED_CREDIT = 'sales-store-update-suspended-credit',
  SET_SALES_HEADER_ICONS = 'set-sales-header-icons',
  UPDATE_PAYMENT_DATA = 'sales-update-payment-data',
  UPDATE_FULL_PAYMENT_DATA = 'sales-update-full-payment-data',
  SET_CART_TYPE = 'set-cart-type',
  SET_SELECTED_CART_TYPE = 'set-selected-cart-type',
  RESET_CART_TYPE = 'reset-cart-type',
  SET_LAYBY_INFO = 'set-layby-info',
  SET_LAYBY_STATUS = 'set-layby-status',
  SET_TOTAL_PRICE_DEPOSIT = 'set-total-price-deposit',
  SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD = 'set-total-price-deposit-payment-method',
  SET_STORE_SALE_LAYBY = 'set-store-sale-layby',
  RESET_LAYBY = 'reset-layby',
  RESET_LAYBY_DATA = 'reset-layby-data',
  SET_LAYBY_CONSUMER_LIST = 'set-layby-list',
  LAYBY_RESUME = 'layby-resume',
  RESET_LAYBY_RESUME_DATA = 'reset-layby-resume-data',
  SET_CAPI_LAYBY_DELETE = 'set-capi-layby-delete',
  REMOVE_CAPI_LAYBY_DELETE = 'remove-capi-layby-delete',
  SET_LAYBY_DEPOSIT_VALID = 'set-layby-deposit-valid',
  SET_GIFT_RECEIPT = 'set-gift-receipt',
  REMOVE_LAYBY_CURRENT_SALE = 'remove-layby-current-sale',
  SET_USED_CASH = 'set-used-cash',
  CLEAR_USED_CASH = 'sale-clear-used-cash',
  SET_CODICE_MOVIMENTO = 'set-codice-movimento',
  SET_DATI_STORNO = 'set-dati-storno',
  SET_DATI_STORNO_CURRENTSALE = 'set-sati-storno-in-currentsale',
  SET_TOTAL_CAPI = 'set-total-capi',
  SET_DISABLED_CONFIRM = 'set-disabled-confirm',
  CHECK_PRODUCTS_STOCK = 'check-product-stock',
  SET_CAN_CHANGE_CART_TYPE = 'set-can-change-cart-type',
  RESET_DATI_STORNO = 'reset-dati-storno',
  CHECK_ANTIRICICLAGGIO_RESULT = 'check-antireciclaggio-resutl',
  SET_INFO_GIACENZA_CAPI = 'set-info-giacenza-capi',
  REMOVE_INFO_GIACENZA_CAPI = 'remove-info-giacenza-capi',
  RESET_INFO_GIACENZA_CAPI = 'reset-info-giacenza-capi',
  ADD_COD_COMMESSA = 'add-cod-commessa',
}

enum Actions {
  OPEN_CREDIT_CARD_MODAL = 'open-credit-card-modal',
  CLOSE_CREDIT_CARD_MODAL = 'close-credit-card-modal',
  RESET_STATE = 'reset-state',
  INIT_SALE = 'sale-init',
  SAVE_SALE = 'save-create',
  CREATE_SALE = 'sale-create',
  UPDATE_SALE = 'sale-update',
  HYDRATE_SALES = 'sale-hydrate',
  HYDRATE_SALES_SKUS = 'sale-skus-hydrate',
  SUSPEND_SALE = 'sale-suspend',
  TRY_PAYMENT_CREDIT_CARD = 'try-payment-credit-card',
  ADD_SKU = 'sale-add-sku',
  ADD_CAPO = 'sale-add-capo',
  REMOVE_CAPO = 'sale-remove-capo',
  UPDATE_CAPO = 'sale-update-capo',
  SET_CONSUMER = 'sale-set-consumer',
  RESET_CONSUMER = 'sale-reset-consumer',
  SET_CASHIER = 'sale-set-cashier',
  SET_MONEY_CHANGE = 'sale-set-money-change',
  RESET_CASHIER = 'sale-reset-cashier',
  SET_RDV = 'sale-set-rdv',
  RESET_RDV = 'sale-reset-rdv',
  SET_NOTA = 'sale-set-note',
  RESET_NOTA = 'sale-reset-note',
  SET_ANONYMOUS = 'sale-set-anonymous',
  SET_ANONYMOUS_CONSUMER = 'sale-set-anonymous-consumer',
  RESET_ANONYMOUS_CONSUMER = 'sale-reset-anonymous-consumer',
  SET_PAYMENTS = 'sale-set-payments',
  RESET_PAYMENTS = 'sale-reset-payments',
  RESET_ONLY_PAYMENTS_TYPE = 'sale-reset-only-payments-type',
  ADD_PAYMENT = 'sale-add-payment',
  EDIT_PAYMENT = 'sale-edit-payment',
  REMOVE_PAYMENT = 'sale-remove-payment',
  ADD_PAYMENT_DATA = 'sale-add-payment-data',
  RESET_DISCOUNTS = 'sale-reset-discounts',
  REMOVE_DISCOUNTS_VALIDATION = 'sale-remove-discounts-validation',
  CONFIRM_DISCOUNTS = 'sale-confirm-discounts',
  RESET_REBATES = 'sale-reset-rebates',
  REMOVE_REBATES_VALIDATION = 'sale-remove-rebates-validation',
  CONFIRM_REBATES = 'sale-confirm-rebates',
  RESET_TAILORING_ALTERATIONS = 'sale-reset-tailoring-alterations',
  CONFIRM_TAILORING_ALTERATIONS = 'sale-confirm-tailoring-alterations',
  DELETE_UNCONFIRMED_EXTRA_SERVICES = 'sale-delete-unconfirmed-extra-services',
  RESET_CAPO_ALTERATIONS = 'sale-reset-capo-alterations',
  SET_DOCUMENTO_DATI = 'sale-set-documento-dati',
  SET_DOCUMENTO_SPEDIZIONE = 'sale-set-documento-spedizione',
  RESET_DOCUMENTO_DATI = 'sale-reset-documento-dati',
  SET_DOCUMENTO_CODICE = 'sale-set-documento-codice',
  RESET_DOCUMENTO_CODICE = 'sale-reset-documento-codice',
  COMPUTE_TOTALS = 'sale-compute-totals',
  SET_DATA_VISIBILITY = 'set-data-visibility',
  PAY_GIFT_CARD = 'pay-gift-card',
  GET_NEW_TRANSACTION_ID = 'get-new-transaction-id',
  CLEAR_TRANSACTION_ID = 'clear-transaction-id',
  UPDATE_PAYMENT_DATA = 'sale-update-payment-data',
  UPDATE_FULL_PAYMENT_DATA = 'sale-update-full-payment-data',
  CLOSE_SALE = 'sale-close-sale',
  SET_CART_TYPE = 'set-cart-type',
  SET_SELECTED_CART_TYPE = 'set-selected-cart-type',
  RESET_CART_TYPE = 'reset-cart-type',
  SET_LAYBY_INFO = 'set-layby-info',
  SET_LAYBY_STATUS = 'set-layby-status',
  SET_TOTAL_PRICE_DEPOSIT = 'set-total-price-deposit',
  SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD = 'set-total-price-deposit-payment-method',
  SET_STORE_SALE_LAYBY = 'set-store-sale-layby',
  RESET_LAYBY = 'reset-layby',
  RESET_LAYBY_DATA = 'reset-layby-data',
  RESET_LAYBY_RESUME_DATA = 'reset-layby-resume-data',
  SET_LAYBY_CONSUMER_LIST = 'set-layby-list',
  LAYBY_RESUME = 'layby-resume',
  SET_CAPI_LAYBY_DELETE = 'set-capi-layby-delete',
  REMOVE_CAPI_LAYBY_DELETE = 'remove-capi-layby-delete',
  CLEAR_SALE_CAPI = 'clear-sale-capi',
  SET_LAYBY_DEPOSIT_VALID = 'set-layby-deposit-valid',
  SET_GIFT_RECEIPT = 'set-gift-receipt',
  REMOVE_LAYBY_CURRENT_SALE = 'remove-layby-current-sale',
  SET_USED_CASH = 'set-used-cash',
  CLEAR_USED_CASH = 'clear-used-cash',
  SET_CODICE_MOVIMENTO = 'set-codice-movimento',
  SET_DATI_STORNO = 'set-dati-storno',
  SET_DATI_STORNO_CURRENTSALE = 'set-dati-storno-currentsale',
  SET_TOTAL_CAPI = 'set-total-capi',
  SET_DISABLED_CONFIRM = 'set-disabled-confirm',
  CHECK_PRODUCTS_STOCK = 'check-product-stock',
  SET_CAN_CHANGE_CART_TYPE = 'set-can-change-cart-type',
  SET_INFO_GIACENZA_CAPI = 'set-info-giacenza-capi',
  REMOVE_INFO_GIACENZA_CAPI = 'remove-info-giacenza-capi',
  RESET_INFO_GIACENZA_CAPI = 'reset-info-giacenza-capi',
  STORE_ID_TRANSAZIONE = 'sales-store-id-transazione',
  STORE_CREATION_DATE = 'sales-store-creation-date',
  SET_SALE_DATI_PROMO = 'set-sale-dati-promo',
  ADD_COD_COMMESSA = 'add-cod-commessa',
  // old actions
  UPDATE_TAILORING = 'sales-updateTailoring',
  SET_INITIAL_PRICE = 'sale-setInitialPrice',
  SET_FINAL_PRICE = 'sale-setFinalPrice',
  SET_TOTAL_PAYMENTS = 'sale-setTotalPayments',
  UPDATE_AVAILABLE_PAYMENTS_PROGRESSIVE = 'sales-setAvailablePaymentsProgressive',
  UPDATE_CURRENT_CAPO_ALTERATIONS = 'sales-updateCurrentCapoAlteration',
  CHECK_SALES_TRACE = 'sales-check-trace',
  CHECK_DUPLICATE_SKU = 'sales-checkDuplicateSku',
  UPDATE_PRODUCT_DETAIL = 'sales-updateProductDetail',
  UPDATE_PAYMENT_VALIDATION = 'sales-updatePaymentValidation',
  STORE_PAYMENT_AMOUNT = 'sales-storePaymentAmount',
  CHECK_COUPON = 'sales-checkCoupon',
  VALIDATE_COUPON = 'sales-validateCoupon',
  CONFIRM_COUPON = 'sales-confirmCoupon',
  DELETE_COUPON = 'sales-resetCoupon',
  UPDATE_COUPON_VALUE = 'sales-updateCouponValue',
  SET_TAILORING_VALIDATIONS_FALSE = 'sales-setTailoringValidationsFalse',
  ACTIVATE_GIFT_CARDS = 'sales-activateGiftCards',
  UPDATE_SUSPENDED_CREDIT = 'sales-update-suspended-credit',
  FETCH_SALES_HEADER_ICONS = 'fetch-sales-header-icons',
  CHECK_DATI_ANTIRICICLAGGIO = 'sales-check-dati-antiriciclaggio',
  STAMPA_RIEPILOGO_ANTIRICICLAGGIO = 'sales-stampa-riepilogo-antiriciclaggio',
  CHECK_TRANSACTION = 'check-transaction',
  RESET_DATI_STORNO = 'redset-dati-storno',
  RESET_DOCUMENTO_SPEDIZIONE = 'reset-documento-spedizione',
}

enum Getters {
  GET_ALL_PRODUCT = 'get-all-product',
  PURCHASE_IS_ABOVE_THRESHOLD = 'is-anty-money-laundering',
  GET_CONSUMER_SAME_COUNTRY_CONDE = 'is-anti-money-laundering',
  GET_RDV = 'sales-getter-rdv',
  GET_CASHIER = 'sales-getter-cashier',
  GET_CONSUMER = 'sales-getter-consumer',
  GET_RDV_ID = 'sales-getter-rdv-id',
  GET_CASHIER_ID = 'sales-getter-cashier-id',
  GET_ANONYMOUS_ID = 'sales-getter-anonymous-id',
  GET_CONSUMER_ID = 'sales-getter-consumer-id',
  GET_RDV_LABEL = 'sales-getRdv-label',
  GET_CASHIER_LABEL = 'sales-getCashier-label',
  GET_CUSTOMER_LABEL = 'sales-getCustomer-label',
  GET_PAYMENTS = 'sales-getPayments',
  GET_ONLY_PAYMENTS = 'sales-getOnlyPayments',
  GET_PAYMENTS_TOTAL = 'sales-getPayments-total',
  GET_TOTAL_AMOUNT_CASHED_IN = 'sales-getPayments-totalAmountCashedIn',
  GET_DISCOUNT = 'sales-getDiscount',
  GET_DISCOUNT_DATA = 'sales-getDiscountValue',
  GET_LOYALTY_PROGRAM_DISCOUNT = 'sales-getLoyaltyProgramDiscount',
  GET_DOCUMENTO_SPEDIZIONE = 'sales-getDocumentoSpedizione',
  GET_REBATE = 'sales-getRebate',
  GET_TAILORING = 'sales-getTailoring',
  GET_PRODUCT_TAILORING = 'sales-getProductTailoring',
  GET_EXTRA_TAILORING = 'sales-getExtraTailoring',
  GET_FREE_EXTRA_TAILORING = 'sales-getFreeExtraTailoring',
  GET_EXTRA_TAILORING_PRICE = 'sales-getExtraTailoring-price',
  GET_TOTAL_PRICE = 'sales-getPrice-total',
  GET_CAPI_PRICE = 'sales-getPrice-capi',
  GET_CAPI = 'sales-getCapi',
  GET_CAPO = 'sales-get-capo',
  CHECK_FOR_ONE_CASH_PAYMENT = 'sales-checkOneCashPayment',
  CHECK_FOR_ONE_SUSPENDED_CREDIT_PAYMENT = 'sales-checkOneSuspendedCreditPayment',
  GET_CURRENT_SALE = 'sales-getCurrentSale',
  GET_ANONYMOUS = 'sales-getAnonymous',
  GET_CURRENT_CAPO_TAILORING = 'sales-getCurrentCapoTailoring',
  GET_PRODUCT = 'sales-get-product',
  GET_CAPO_ALTERATION = 'sales-get-capo-alteration',
  GET_CAPO_ALTERATION_TOTAL = 'sales-get-capo-alteration-total',
  GET_CREDITO_SOSPESO_ENABLED = 'get-credito-sospeso-enable',
  GET_SALE_IMPORTO_FINALE = 'get-sale-importo-finale',
  GET_CREDIT_CARD_PAYMENT_VALID = 'get-sale-credit-card-payment-valid',
  GET_CARDS_PAYMENT_IN_RETURNS_VALID = 'get-sale-credit-card-payment-in-returns-valid',
  GET_GIFT_CARD_PAYMENT_VALID = 'get-gift-card-payment-valid',
  GET_COUPON = 'get-sale-coupon',
  GET_SALE_STORE_STATUS = 'get-sale-store-status',
  GET_CART_VIEW = 'get-sale-cart-view',
  GET_DATA_VISIBILITY = 'get-data-visibility',
  GET_IS_GIFT_CARD_SALE = 'get-is-gift-card-sale',
  GET_SUSPENDED_CREDIT = 'get-suspended-credit',
  GET_SALES_HEADER_ICONS = 'get-sales-header-icons',
  GET_PAYMENT_DATA = 'get-payment-data',
  GET_PAYMENT_DATA_CASH = 'get-payment-data-cash',
  GET_CART_TYPE = 'get-cart-type',
  GET_MAX_IMPORT_CHEQUE = 'get-max-import-cheque',
  GET_SELECTED_CART_TYPE = 'get-selected-cart-type',
  GET_LAYBY_INFO = 'get-layby-info',
  GET_LAYBY_STATUS = 'get-layby-status',
  GET_LAYBY_STATUS_EQUAL_TO = 'get-layby-status-equal-to',
  GET_IS_DEPOSIT_FLOW = 'GET_IS_DEPOSIT_FLOW',
  GET_TOTAL_PRICE_DEPOSIT = 'get-total-price-deposit',
  GET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD = 'get-total-price-deposit-spotify-payment-method',
  GET_LAYBY_MINIMUM_DEPOSIT = 'get-layby-minimum-deposit',
  GET_LAYBY_CONSUMER_LIST = 'get-layby-list',
  GET_LAYBY_RESUME_DATA = 'get-layby-resume-data',
  GET_LAYBY_DEPOSIT_VALID = 'get-layby-deposit-valid',
  GET_IS_GROUP_CONSUMER = 'get-is-group-consumer',
  GET_LAYBY_CALC_MINIMUM_DEPOSIT = 'get-layby-calc-minimum-deposit',
  GET_IS_GIFT_CARD_SAVE_IN_PROCESS = 'get-gift-card-save-in-process',
  GET_USED_CASH = 'get-used-cash',
  GET_SUM_OF_DEBTPAYMENTS_VALIDATION_GIFTCARDSALE = 'get_sum_of_debtpaymets_validation_giftcardsale',
  IS_FREE_RETURN = 'is-free-return',
  IS_ALL_FREE_RETURN = 'is-all-free-return',
  GET_CODICE_MOVIMENTO = 'get-codice-movimento',
  GET_DATI_STORNO = 'get-dati-storno',
  GET_DISABLED_CONFIRM = 'get-disabled-confirm',
  GET_CAN_CHANGE_CART_TYPE = 'get-can-change-cart-type',
  GET_TAILORING_NO_EXTRA = 'get-tailoring-no-extra',
  IS_CASH_AMOUNT_ABOVE_MAXPAYABLE = 'is-cash-amount-above-maxpayable',
  GET_CHECK_ANTIRICICLAGGIO_RESULT = 'get-check-antireciclaggio-result',
  GET_INFO_GIACENZA_CAPI = 'get-info-giacenza-capi',
  GET_QUANTITY_INFO_GIACENZA_CAPI = 'get-quantity-info-giacenza-capi',
  IS_ONLY_TAILORING_ALTERATION_FREE_SALE = 'is-only-tailoring-alteration-free-sale',
  IS_ONLY_FREE_SALE = 'is-only-free-sale',
  IS_OSS_SALE = 'is-oss-sale',
}
const initDatiStorno = (): datiStornoD => ({
  num_bollettina: '',
  data_bollettina: '',
  possiede_scontrino: 0,
  original_sale_type: 'SALE',
})

const initState = (): SalesState => ({
  currentSale: {},
  product_detail: {},
  progressivo_capi: 0,
  progressivo_pagamenti: 0,
  consumer: undefined,
  isAnonymous: false,
  isGroupConsumer: false,
  selected_rdv: undefined,
  selected_cashier: undefined,
  skuAlertOpen: false,
  cartView: 'carousel',
  consumerStatistics: undefined,
  modalCreditCardInstance: undefined,
  suspendedCredit: {
    pagamentoRemotoPreferito: '',
    tipoProdotto: '',
    importo: '',
  },
  headerIcons: {} as GetConsumerExtraInfoDataAttributes,
  paymentData: { chequeData: {}, cashData: {} } as PaymentMethodForm,
  cartType: [
    {
      id: LaybyCartTypeID.STANDARD,
      value: 'standard',
      label: 'pos_common.standard',
    },
    {
      id: LaybyCartTypeID.ACCONTO,
      value: 'acconto',
      label: 'pos_sale.layby',
    },
    // hidden until complete feature
    // {
    //   id: LaybyCartTypeID.REMOTE_SALE,
    //   value: 'remote-sale',
    //   label: 'pos_cash_register.cash_remote_sales'
    // }
  ],
  selectedCartType: {},
  laybyInfo: {
    totale: 0,
    acconto_minimo: 0,
    importo_oltre_acconto_minimo: 0,
    importo_mancante: 0,
    importo_rendibile: 0,
    importo_trattenuto: 0,
    prossimo_pagamento: 0,
    amountForPayment: 0,
    cancellato: 0,
    importo_pagato: 0,
    importo_pagamento: 0,
  },
  laybyStatus: LaybyStatusType.RECAP,
  totalPriceDeposit: 0,
  totalPriceDepositPaymentMethod: 0,
  laybyConsumerList: [],
  laybyResumeData: {},
  maxImportCheque: '',
  laybyDepositValid: false,
  saveGiftCardInProcess: false,
  usedCash: {},
  dati_storno: initDatiStorno(),
  disabledConfirm: false,
  canChangeCartType: {
    value: true,
    message: '',
  },
  checkAntireciclaggioResult: false,
  infoGiacenzaCapi: {},
  pk_consumer: '',
})

const salesStore = {
  namespaced: true,
  state: initState,
  mutations: {
    [Mutations.SET_MODAL_CREDIT_CARD_INSTANCE]: (
      state: SalesState,
      payload: modalCreditCardInstanceType
    ): void => {
      state.modalCreditCardInstance = payload
    },
    [Mutations.RESET_STATE]: (state: SalesState): void => {
      Object.assign(state, initState())
    },
    [Mutations.RESET_DATI_STORNO]: (state: SalesState): void => {
      Object.assign(state.dati_storno, initDatiStorno())
    },
    [Mutations.CHECK_ANTIRICICLAGGIO_RESULT]: (
      state: SalesState,
      payload: boolean
    ): void => {
      state.checkAntireciclaggioResult = payload
    },
    [Mutations.STORE_SALE]: (
      state: SalesState,
      sale: ApiV1SalesDataAttributes | ApiV1SalesSaleIdDataAttributes
    ): void => {
      Object.assign(state.currentSale, sale)
    },
    [Mutations.CLEAR_SALE]: (state: SalesState): void => {
      state.currentSale = {}
    },
    [Mutations.STORE_SALE_CAPI]: (
      state: SalesState,
      capi:
        | ApiV1SalesDataAttributesCapi[]
        | ApiV1SalesSaleIdDataAttributesCapi[]
    ): void => {
      state.currentSale.capi = capi
    },
    [Mutations.CLEAR_SALE_CAPI]: (state: SalesState): void => {
      state.currentSale.capi = []
    },
    [Mutations.STORE_SALE_PAGAMENTI]: (
      state: SalesState,
      pagamenti:
        | ApiV1SalesDataAttributesPagamenti[]
        | ApiV1SalesSaleIdDataAttributesPagamenti[]
    ): void => {
      state.currentSale.pagamenti = pagamenti
    },
    [Mutations.CLEAR_SALE_PAGAMENTI]: (state: SalesState): void => {
      state.currentSale.pagamenti = []
    },
    [Mutations.CLEAR_ONLY_PAYMENTS_TYPE]: (state: SalesState): void => {
      state.currentSale.pagamenti = state.currentSale.pagamenti?.filter((p) => {
        return p.codice_movimento !== TipiMovimenti.CONTABILITA_PAGAMENTO
      })
    },
    [Mutations.STORE_SALE_IMPORTO_FINALE]: (
      state: SalesState,
      importo: number
    ): void => {
      state.currentSale.importo_finale = importo
    },
    [Mutations.STORE_CONSUMER]: (
      state: SalesState,
      payload: StoreConsumerPayload
    ): void => {
      if (typeof payload.groupConsumer === 'undefined') {
        payload.groupConsumer = false
      }
      state.currentSale.pk_consumer =
        (payload.consumer?.pk_consumer as string) ?? null

      if (payload.consumer === null) {
        delete state.consumer
      } else {
        try {
          state.consumer = JSON.parse(JSON.stringify(payload?.consumer))
        } catch {
          state.consumer = {}
        }
      }

      state.isGroupConsumer = payload.groupConsumer
    },
    [Mutations.CLEAR_SALE_IMPORTO_FINALE]: (state: SalesState): void => {
      state.currentSale.importo_finale = 0
    },
    [Mutations.STORE_SALE_IMPORTO_PAGATO]: (
      state: SalesState,
      importo: number
    ): void => {
      state.currentSale.importo_pagato = importo
    },
    [Mutations.CLEAR_SALE_IMPORTO_PAGATO]: (state: SalesState): void => {
      state.currentSale.importo_pagato = 0
    },
    [Mutations.STORE_PRODUCTS]: (
      state: SalesState,
      products: { [key: string]: GetSkuDataAttributes }
    ): void => {
      state.product_detail = products
    },
    [Mutations.CLEAR_PRODUCTS]: (state: SalesState): void => {
      state.product_detail = {}
    },
    [Mutations.STORE_PROGRESSIVO_CAPO]: (
      state: SalesState,
      progressivo: number
    ): void => {
      state.progressivo_capi = progressivo
    },
    [Mutations.CLEAR_PROGRESSIVO_CAPO]: (state: SalesState): void => {
      state.progressivo_capi = 0
    },
    [Mutations.STORE_PROGRESSIVO_PAGAMENTI]: (
      state: SalesState,
      progressivo: number
    ): void => {
      state.progressivo_pagamenti = progressivo
    },
    [Mutations.CLEAR_PROGRESSIVO_PAGAMENTI]: (state: SalesState): void => {
      state.progressivo_pagamenti = 0
    },
    [Mutations.CLEAR_CONSUMER]: (state: SalesState): void => {
      state.consumer = undefined
      state.isGroupConsumer = false
    },
    [Mutations.STORE_ANONYMOUS_FLAG]: (
      state: SalesState,
      flag: boolean
    ): void => {
      state.isAnonymous = flag
    },
    [Mutations.CLEAR_ANONYMOUS_FLAG]: (state: SalesState): void => {
      state.isAnonymous = false
    },
    [Mutations.STORE_RDV]: (
      state: SalesState,
      rdv: GetCashiersAttributes
    ): void => {
      state.selected_rdv = rdv
    },
    [Mutations.CLEAR_RDV]: (state: SalesState): void => {
      state.selected_rdv = undefined
    },
    [Mutations.STORE_CASHIER]: (
      state: SalesState,
      cashier: GetCashiersAttributes
    ): void => {
      state.selected_cashier = cashier
    },
    [Mutations.CLEAR_CASHIER]: (state: SalesState): void => {
      state.selected_cashier = undefined
      state.currentSale.cod_cassiera = undefined
    },
    [Mutations.UPDATE_PRODUCT_DETAIL]: (
      state: SalesState,
      capo: ApiV1SalesDataAttributesCapi
    ): void => {
      if (capo?.progressivo) {
        state.product_detail[capo.progressivo] = {
          ...state.product_detail[capo.progressivo],
          ...capo,
        }
      }
    },
    [Mutations.STORE_SKU_ALERT_OPEN]: (
      state: SalesState,
      flag: boolean
    ): void => {
      state.skuAlertOpen = flag
    },
    [Mutations.STORE_CREATION_DATE]: (
      state: SalesState,
      date: string
    ): void => {
      state.currentSale.data_creazione = date
    },
    [Mutations.UPDATE_SALE_STATUS]: (
      state: SalesState,
      status: CodiceStatoVendita
    ): void => {
      state.currentSale.codice_stato = status
      state.currentSale.capi?.forEach((c) => {
        c.codice_stato = status
      })
    },
    [Mutations.STORE_ID_TRANSAZIONE]: (
      state: SalesState,
      saleId: number | string
    ): void => {
      const id = typeof saleId === 'number' ? saleId : parseInt(saleId, 10)
      state.currentSale.id_transazione = id
      state.currentSale.capi?.forEach((c) => {
        c.id_transazione = id
      })
      if (state.currentSale.pagamenti) {
        state.currentSale.pagamenti?.forEach((c) => {
          c.id_transazione = id
        })
      }
    },
    [Mutations.CLEAR_ID_TRANSAZIONE]: (state: SalesState): void => {
      const id = undefined
      state.currentSale.id_transazione = id
      state.currentSale.capi?.forEach((c) => {
        c.id_transazione = id
      })
      if (state.currentSale.pagamenti) {
        state.currentSale.pagamenti?.forEach((c) => {
          c.id_transazione = id
        })
      }
    },
    [Mutations.STORE_CART_VIEW]: (state: SalesState, view: string): void => {
      state.cartView = view
    },
    [Mutations.STORE_DATA_VISIBILITY]: (
      state: SalesState,
      payload: Statistics
    ): void => {
      state.consumerStatistics = payload
    },
    [Mutations.UPDATE_SUSPENDED_CREDIT]: (
      state: SalesState,
      payload: SuspendedCreditForm
    ): void => {
      state.suspendedCredit = payload
    },
    [Mutations.SET_SALES_HEADER_ICONS]: (
      state: SalesState,
      headerIcons: GetConsumerExtraInfoDataAttributes
    ) => (state.headerIcons = headerIcons),
    [Mutations.UPDATE_PAYMENT_DATA]: (
      state: SalesState,
      payload: { changed: { id: string; value: string }; paymentKey: string }
    ): void => {
      if (payload.paymentKey === PaymentKeys.CHEQUE) {
        Object.assign(state.paymentData.chequeData, {
          [payload.changed.id]: payload.changed.value,
        })
      } else if (payload.paymentKey === PaymentKeys.CASH) {
        Object.assign(state.paymentData.cashData, {
          [payload.changed.id]: payload.changed.value,
        })
      }
    },
    [Mutations.UPDATE_FULL_PAYMENT_DATA]: (
      state: SalesState,
      payload: {
        paymentForm:
          | CashGenericPaymentMethodForm
          | ChequeGenericPaymentMethodForm
        paymentKey: string
      }
    ): void => {
      if (payload.paymentKey === 'cheque') {
        state.paymentData.chequeData = payload.paymentForm
      }
      if (payload.paymentKey === 'cash') {
        state.paymentData.cashData = payload.paymentForm
      }
      state.checkAntireciclaggioResult = false
    },
    [Mutations.SET_CART_TYPE]: (
      state: SalesState,
      payload: CartType[]
    ): void => {
      state.cartType = payload
    },
    [Mutations.SET_SELECTED_CART_TYPE]: (
      state: SalesState,
      payload: CartType
    ): void => {
      state.selectedCartType = payload
    },
    [Mutations.RESET_CART_TYPE]: (state: SalesState): void => {
      state.selectedCartType = initState().cartType[0]
    },
    [Mutations.SET_LAYBY_INFO]: (
      state: SalesState,
      payload: LaybyInfo
    ): void => {
      state.laybyInfo = payload
    },
    [Mutations.SET_LAYBY_STATUS]: (
      state: SalesState,
      payload: LaybyStatusType
    ): void => {
      state.laybyStatus = payload
    },
    [Mutations.SET_TOTAL_PRICE_DEPOSIT]: (
      state: SalesState,
      payload: {
        value: number
      }
    ): void => {
      state.totalPriceDeposit = payload.value
    },
    [Mutations.SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD]: (
      state: SalesState,
      payload: {
        value: number
      }
    ): void => {
      state.totalPriceDepositPaymentMethod = payload.value
    },
    [Mutations.SET_STORE_SALE_LAYBY]: (
      state: SalesState,
      payload: LaybyInfo
    ): void => {
      if (state.laybyStatus === LaybyStatusType.RECAP_DELETE) {
        payload['cancellato'] = 1
      }
      state.currentSale.layby = payload
    },
    [Mutations.RESET_LAYBY]: (state: SalesState): void => {
      const initLayby = initState()
      delete state.currentSale.layby
      state.laybyInfo = initLayby.laybyInfo
      state.laybyStatus = initLayby.laybyStatus
      state.selectedCartType = initLayby.cartType[0]
      state.laybyConsumerList = initLayby.laybyConsumerList
      state.laybyDepositValid = initLayby.laybyDepositValid
      state.consumer = undefined
    },
    [Mutations.RESET_LAYBY_DATA]: (state: SalesState): void => {
      delete state.currentSale.layby
      state.laybyInfo = initState().laybyInfo
    },
    [Mutations.RESET_LAYBY_RESUME_DATA]: (state: SalesState): void => {
      state.laybyResumeData = {}
    },

    [Mutations.SET_LAYBY_CONSUMER_LIST]: async (
      state: SalesState,
      pkConsumer: string
    ): Promise<void> => {
      const filterDataA = store.getters[ConfigGetters.GET_CUSTOM_DATE]
        ? store.getters[ConfigGetters.GET_CUSTOM_DATE]
        : undefined

      const r = await consumerDataApi.apiV1ConsumersIconsPkConsumerGet(
        pkConsumer,
        filterDataA
      )
      state.laybyConsumerList = r.data.data?.attributes?.layby
    },
    [Mutations.LAYBY_RESUME]: (
      state: SalesState,
      payload: LaybyElementAttr
    ): void => {
      state.laybyResumeData = payload
      state.laybyStatus = LaybyStatusType.RECAP_RESUME
      state.selectedCartType = initState().cartType[1]
    },
    [Mutations.SET_CAPI_LAYBY_DELETE]: (state: SalesState): void => {
      state.currentSale.capi?.map(
        (c: CapiLaybyDelete) => (c.layby_delete = true)
      )
    },
    [Mutations.REMOVE_CAPI_LAYBY_DELETE]: (state: SalesState): void => {
      state.currentSale.capi?.map((c: CapiLaybyDelete) => delete c.layby_delete)
    },
    [Mutations.SET_LAYBY_DEPOSIT_VALID]: (
      state: SalesState,
      payload: boolean
    ): void => {
      state.laybyDepositValid = payload
    },
    [Mutations.SET_GIFT_CARD_SAVE_IN_PROCESS]: (
      state: SalesState,
      payload: boolean
    ): void => {
      state.saveGiftCardInProcess = payload
    },
    [Mutations.SET_GIFT_RECEIPT]: (state: SalesState, payload: 1 | 0): void => {
      state.currentSale.flag_stampa_scontrino_cortesia = payload
    },
    [Mutations.REMOVE_LAYBY_CURRENT_SALE]: (state: SalesState): void => {
      if ('layby' in state.currentSale) {
        delete state.currentSale?.layby
      }
    },
    [Mutations.SET_USED_CASH]: (state: SalesState, payload: UsedCash): void => {
      state.usedCash = payload
    },
    [Mutations.CLEAR_USED_CASH]: (state: SalesState): void => {
      state.usedCash = {}
    },
    [Mutations.SET_CODICE_MOVIMENTO]: (
      state: SalesState,
      payload: TipiVendita | undefined
    ): void => {
      if (payload) {
        state.currentSale.codice_movimento = payload
      } else {
        state.currentSale.codice_movimento = TipiVendita.VENDITA
      }
    },
    [Mutations.SET_DATI_STORNO]: (
      state: SalesState,
      payload: datiStornoD
    ): void => {
      state.dati_storno = Object.assign({}, state.dati_storno, payload)
    },
    [Mutations.SET_DATI_STORNO_CURRENTSALE]: (
      state: SalesState,
      payload: datiStornoD
    ): void => {
      try {
        const dati_doc = JSON.parse(state.currentSale.dati_documenti)
        state.currentSale.dati_documenti = JSON.stringify({
          ...dati_doc,
          dati_storno: payload,
        })
      } catch (e) {
        // TODO: Gestire le casistiche di errore
      }
    },
    [Mutations.SET_TOTAL_CAPI]: (state: SalesState): void => {
      state.currentSale.capi?.map((c) => {
        c.importo_finale =
          c.codice_movimento === TipiVendita.STORNO ||
          c.codice_movimento === TipiVendita.RESO
            ? Math.abs(c.importo_finale) * -1
            : Math.abs(c.importo_finale)
        c.importo_iniziale = Math.abs(c.importo_iniziale)
        // TODO: STAB: va' modificato solo importo finale, importo iniziale non deve variare
        // DOC: FIX: è stato' modificato solo importo finale, importo iniziale non deve variare FGABS-2244
        return {
          ...c,
        }
      })
    },
    [Mutations.SET_DISABLED_CONFIRM]: (
      state: SalesState,
      payload: boolean
    ): void => {
      state.disabledConfirm = payload
    },
    [Mutations.SET_CAN_CHANGE_CART_TYPE]: (
      state: SalesState,
      payload: CanChangeCartTypeD
    ): void => {
      state.canChangeCartType = payload
    },
    [Mutations.CHECK_PRODUCTS_STOCK]: (state: SalesState): void => {
      const currentStock = []
      const disallowdStockConfirm = [
        StockIcon.NOLISTDATA,
        StockIcon.NODATASTOCK,
      ]
      state.currentSale?.capi?.forEach((capo) => {
        const st = stockIcon(capo)
        if (disallowdStockConfirm.includes(<StockIcon>st)) {
          currentStock.push(st)
          store.dispatch(SalesActions.SET_CAN_CHANGE_CART_TYPE, {
            value: false,
            message: 'pos_stock.no_change_cart_type',
          })
        } else {
          store.dispatch(SalesActions.SET_CAN_CHANGE_CART_TYPE, {
            value: true,
            message: '',
          })
        }
      })
      store.dispatch(SalesActions.SET_DISABLED_CONFIRM, currentStock.length > 0)
    },
    [Mutations.SET_CAN_CHANGE_CART_TYPE]: (
      state: SalesState,
      payload: boolean
    ): void => {
      state.canChangeCartType = payload
    },
    [Mutations.UPDATE_SALE_DATA]: (state: SalesState, payload: any): void => {
      state.currentSale = payload
    },
    [Mutations.SET_INFO_GIACENZA_CAPI]: (
      state: SalesState,
      payload: InfoGiacenzaCapiD
    ): void => {
      const currentItem = state.infoGiacenzaCapi[payload.sku]
      if (!currentItem) {
        state.infoGiacenzaCapi[payload.sku] = payload
      } else {
        state.infoGiacenzaCapi[payload.sku] = Object.assign({}, currentItem, {
          quantity: store.getters[SalesGetters.GET_QUANTITY_INFO_GIACENZA_CAPI](
            payload.sku
          ),
        })
      }
    },
    [Mutations.REMOVE_INFO_GIACENZA_CAPI]: (
      state: SalesState,
      payload: string
    ): void => {
      const currentItem = state.infoGiacenzaCapi[payload]
      if (currentItem) {
        if (currentItem.quantity === 1) {
          delete state.infoGiacenzaCapi[payload]
        } else {
          state.infoGiacenzaCapi[payload] = Object.assign({}, currentItem, {
            quantity:
              store.getters[SalesGetters.GET_QUANTITY_INFO_GIACENZA_CAPI](
                payload
              ),
          })
        }
      }
    },
    [Mutations.RESET_INFO_GIACENZA_CAPI]: (state: SalesState): void => {
      state.infoGiacenzaCapi = {}
    },
    [Mutations.ADD_COD_COMMESSA]: (
      state: SalesState,
      payload: string
    ): void => {
      state.currentSale.capi = state.currentSale.capi?.map((c) =>
        Object.assign({}, c, {
          cod_commessa: c.cod_commessa ? c.cod_commessa : payload || '',
        })
      )
    },
  },
  actions: {
    [Actions.OPEN_CREDIT_CARD_MODAL]: (
      context: ActionContext<SalesState, RootState>
    ): Promise<TipoStornoEnum | undefined> => {
      return new Promise((resolve) => {
        const instance = modalController.open({
          type: ModalTypes.INFO,
          component: 'FeModalCreditCard',
          title: i18n.global.t('pos_common.attention'),
          message: i18n.global.t('pos_returns.modal_credit_card_message'),
          confirmActionButton: false,
          customFooter: true,
          customClass: 'fe-custom-modal-returns',
          customProps: {
            refundType: 'DELETELAYBY',
          },
          customEventHandlers: {
            returnTransaction: () => resolve(TipoStornoEnum.Storno),
            creditAmount: () => resolve(TipoStornoEnum.Accredito),
          },
          closedAction: () => resolve(undefined),
        })
        context.commit(Mutations.SET_MODAL_CREDIT_CARD_INSTANCE, instance)
      })
    },
    [Actions.CLOSE_CREDIT_CARD_MODAL]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      if (context.state.modalCreditCardInstance) {
        context.state.modalCreditCardInstance.close()
        context.commit(Mutations.SET_MODAL_CREDIT_CARD_INSTANCE, undefined)
      }
    },
    [Actions.RESET_STATE]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(Mutations.RESET_STATE)
    },
    [Actions.RESET_DATI_STORNO]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(Mutations.RESET_DATI_STORNO)
    },
    [Actions.INIT_SALE]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      const sid = store.getters[AuthGetters.GET_SID]
      context.commit(Mutations.STORE_SALE, {
        pk_consumer: '',
        sid: sid,
        tipo_applicazione_apertura: 'POSWEB',
        tipo_applicazione_chiusura: 'POSWEB',
        cod_cassa: context.rootState.configs.setup?.cash_id,
        cod_documento: '',
        cod_negozio: context.rootState.configs.currentStore?.STORE_CODE,
        codice_movimento: TipiVendita.VENDITA,
        codice_stato: 'CURRENT',
        dati_aggiuntivi: '',
        dati_documenti: '{}',
        divisa: context.rootState.configs.currentStore?.STORE_SIGN,
        flag_stampa_documento: 0,
        flag_stampa_scontrino_cortesia: 0,
        id_postazione_apertura: context.rootState.configs.setup?.cash_id,
        id_postazione_chiusura: context.rootState.configs.setup?.cash_id,
        id_transazione: undefined,
        importo_finale: 0,
        importo_iniziale: 0,
        importo_pagato: 0,
        nota: '',
        capi: [],
        pagamenti: [],
        data_creazione: undefined,
      } as ApiV1SalesDataAttributes)
      context.commit(Mutations.CLEAR_ANONYMOUS_FLAG)
      context.commit(Mutations.CLEAR_CONSUMER)
      context.commit(Mutations.CLEAR_CASHIER)
      context.commit(Mutations.RESET_CART_TYPE)
      context.commit(Mutations.CLEAR_RDV)
      context.commit(Mutations.CLEAR_PRODUCTS)
      context.commit(Mutations.CLEAR_PROGRESSIVO_PAGAMENTI)
      context.commit(Mutations.CLEAR_PROGRESSIVO_CAPO)
      context.commit(Mutations.RESET_LAYBY)
      context.dispatch(Actions.RESET_LAYBY_DATA)
      context.commit(Mutations.CLEAR_USED_CASH)
      context.commit(Mutations.RESET_LAYBY_RESUME_DATA)
      context.commit(Mutations.RESET_DATI_STORNO)
      store.dispatch(ConsumersActions.RESET_CURRENT_CONSUMER)
      store.dispatch(ConsumersActions.RESET_STATE)
      store.commit(PaymentsMutations.CLEAR_VALIDATION_PAGAMENTI)
      sessionStorage.removeItem(SessionKeys.CURRENT_SALE)
    },
    [Actions.SAVE_SALE]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      if (context.state.currentSale.id_transazione) {
        context.dispatch(Actions.UPDATE_SALE)
      } else {
        context.dispatch(Actions.CREATE_SALE)
      }
    },
    [Actions.CREATE_SALE]: async (
      context: ActionContext<SalesState, RootState>
    ) => {
      let isSaleOk
      const saleData = context.state.currentSale
      await context.dispatch(Actions.COMPUTE_TOTALS)
      // eslint-disable-next-line require-atomic-updates
      saleData.codice_stato = 'CLOSED'
      saleData.capi?.map((d) => {
        d.codice_stato = 'CLOSED'
        if ('layby_delete' in d) {
          delete d.layby_delete
        }
        if ('error_code' in d) {
          delete d.error_code
        }
        if (d.sku_created) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore -- needed because only here it needs to be a string
          d.composizione = JSON.stringify(context.state.capo.composizione)
        }
        return d
      })

      LoaderController.show({
        section: PageContexts.FRONTOFFICE,
      })

      return salesApi
        .apiV1SalesPost({
          data: {
            type: 'sale',
            attributes: saleData,
          },
          sale_origin: ABSTRID_EA,
          preserve_decimals: true,
        })
        .then(
          async (resp) => {
            await PrintErrorHandler(
              resp.data.data,
              false,
              ErrorDisplayTypes.MODAL
            )
            LoaderController.hide()
            await context.dispatch(Actions.INIT_SALE)
            if (store.getters[LoyaltyGetters.IS_LOYALTY_ACTIVE]) {
              await store.dispatch(
                LoyaltyActions.RESET_CURRENT_SALE_FIDELITY_POINTS
              )
              await store.dispatch(LoyaltyActions.RESET_STATE)
            }
            context.dispatch(
              NotificationsActions.NOTIFY_SUCCESS,
              'pos_sale.sale_success',
              { root: true }
            )
            router.go(-1)
            sessionStorage.removeItem(SessionKeys.LAYBY_RESUME)
            isSaleOk = true
          },
          (error) => {
            context.state.currentSale.codice_stato = 'CURRENT'
            if (error?.response?.data?.errors?.length) {
              let errorMessage = error?.response?.data?.errors[0].detail
              try {
                let errorDetail = errorMessage
                if (errorDetail.indexOf('{') !== -1) {
                  // eslint-disable-next-line quotes
                  errorDetail = errorDetail.replaceAll("'", '"')
                }
                const parseError = JSON.parse(errorDetail)
                errorMessage = Object.entries(parseError).map((el) => {
                  const [, v] = el
                  return v
                })
              } catch (e) {
                errorMessage = error?.response?.data?.errors[0].detail
              }
              if (typeof errorMessage === 'string') {
                context.dispatch(
                  NotificationsActions.NOTIFY_ERROR,
                  i18n.global.t('pos_sale.sale_error') +
                    (errorMessage ? ': \n' + errorMessage : ''),
                  { root: true }
                )
              } else {
                errorMessage.forEach((m) => {
                  context.dispatch(
                    NotificationsActions.NOTIFY_ERROR,
                    i18n.global.t('pos_sale.sale_error') +
                      (m ? ': \n' + m : ''),
                    { root: true }
                  )
                })
              }
            }
            isSaleOk = false
            LoaderController.hide()
            return isSaleOk
          }
        )
    },
    [Actions.UPDATE_SALE]: async (
      context: ActionContext<SalesState, RootState>
    ): Promise<boolean> => {
      LoaderController.show({
        section: PageContexts.DOCUMENTS,
      })
      await context.dispatch(Actions.COMPUTE_TOTALS)
      const saleData = context.state.currentSale
      try {
        const id = `${saleData.cod_negozio},${saleData.id_transazione}`
        context.commit(Mutations.UPDATE_SALE_STATUS, CodiceStatoVendita.CLOSED)

        if (saleData.capi?.length > 0) {
          const enhancedCapi = saleData.capi?.map((c) => {
            if ('error_code' in c) {
              delete c.error_code
            }
            return {
              ...c,
            }
          })

          saleData.capi = enhancedCapi
        }

        const resp = await salesApi.apiV1SalesSaleIdPatch(id, {
          data: {
            id,
            type: 'sale',
            attributes: saleData,
          },
          preserve_decimals: true,
        })
        await PrintErrorHandler(resp.data.data, false, ErrorDisplayTypes.MODAL)
        LoaderController.hide()

        if (store.getters[LoyaltyGetters.IS_LOYALTY_ACTIVE]) {
          await store.dispatch(LoyaltyActions.RESET_STATE)
        }

        await context.dispatch(Actions.INIT_SALE)
        context.dispatch(
          NotificationsActions.NOTIFY_SUCCESS,
          'pos_sale.sale_success',
          { root: true }
        )
        router.go(-1)
        return true
      } catch (error) {
        let errorMessage = 'pos_sale.sale_error'

        if (error?.response?.data?.errors?.length) {
          const details = error?.response?.data?.errors[0].detail || ''
          if (details) {
            errorMessage = details
          }
        }

        context.dispatch(NotificationsActions.NOTIFY_ERROR, errorMessage, {
          root: true,
        })

        LoaderController.hide()
        return false
      }
    },
    [Actions.HYDRATE_SALES]: async (
      context: ActionContext<SalesState, RootState>,
      sale: ApiV1SalesDataAttributes | ApiV1SalesSaleIdDataAttributes
    ): Promise<void> => {
      context.commit(Mutations.STORE_SALE, {
        ...sale,
        pagamenti: [],
        capi: [],
        id_postazione_chiusura:
          context.rootState.configs.setup?.cash_id ||
          sale.id_postazione_chiusura,
        tipo_applicazione_chiusura: 'POSWEB',
      } as ApiV1SalesDataAttributes)

      if (sale.cod_cassiera) {
        const cashiers =
          store.state.cashiers.cashiers.filter(
            (c: GetCashiersAttributes) => c.venditrice === sale.cod_cassiera
          ) || []
        const cashier = cashiers[0]
        context.commit(Mutations.STORE_CASHIER, cashier)
        context.commit(Mutations.STORE_RDV, cashier)
      }

      const matchingAnonymous = store.state.consumers.anonymousConsumer.filter(
        (ac) => ac.pk_consumer === sale.pk_consumer
      )
      const isAnonymous = matchingAnonymous.length > 0

      if (isAnonymous) {
        await store.dispatch(SalesActions.SET_ANONYMOUS, true)
        await context.dispatch(
          Actions.SET_ANONYMOUS_CONSUMER,
          matchingAnonymous.shift()?.pk_consumer
        )
      } else {
        // If the sale we are hydrating has a consumer linked, we have to fetch consumer data
        if (sale.pk_consumer) {
          const consumerResponse = await consumerDataApi.apiV1ConsumersGet(
            'local',
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            {
              params: {
                'filter[pk_consumer_search]': sale.pk_consumer,
              },
            }
          )
          const consumer = consumerResponse.data.data?.shift()
          if (consumer) {
            context.dispatch(Actions.SET_CONSUMER, {
              consumer: consumer.attributes,
            })
          }
        }
      }

      // If we the sale we are hydrating had some products in it, we have to add those SKUs.

      if (sale.capi?.length) {
        await context.dispatch(Actions.HYDRATE_SALES_SKUS, sale.capi)
      }

      if (sale.pagamenti?.length) {
        sale.pagamenti.forEach((p) => {
          // Update current progressive id so that it is kept in sync with what the suspended sale said.
          context.commit(Mutations.STORE_PROGRESSIVO_PAGAMENTI, p.progressivo)
          // Add payment as described by the received sale
          context.dispatch(Actions.ADD_PAYMENT, p)
          // Make sure to update the validation part
          store.commit(PaymentsMutations.STORE_VALIDATION_PAGAMENTI, {
            ...store.state.payments.payments_validator,
            [p.progressivo as number]: true,
          })
        })
        if (context.getters[Getters.GET_CREDIT_CARD_PAYMENT_VALID]) {
          context.dispatch(GenericActions.OPEN_PAYMENTS, undefined, {
            root: true,
          })
        }
        if (context.getters[Getters.GET_COUPON]) {
          context.dispatch(Actions.VALIDATE_COUPON)
        }
      }
      await context.dispatch(Actions.COMPUTE_TOTALS)
      // If we are handling a previously suspended sale, we have to inform APIs that we are restoring that sale
      if (
        typeof sale.id_transazione !== 'undefined' &&
        context.state.currentSale.codice_stato !== 'CURRENT'
      ) {
        context.state.currentSale.codice_stato = 'CURRENT'

        context.state.currentSale.capi?.forEach((c) => {
          c.codice_stato = 'CURRENT'
        })

        const id = `${sale.cod_negozio},${sale.id_transazione}`
        const saleTrace: PatchSale | PostSale = {
          data: {
            id,
            type: 'sales',
            attributes: {
              ...sale,
              codice_stato: 'CURRENT',
            },
          },
        }
        try {
          const response = await salesApi.apiV1SalesSaleIdPatch(
            id as string,
            saleTrace
          )
          const documents = JSON.parse(
            response.data.data?.attributes?.dati_documenti ?? '{}'
          )
          store.dispatch(AddressValidationActions['set-selected-addres'], {
            ...context.getters[
              AddressValidationGetters['get-selected-address']
            ],
            destination: documents.shipping_destination,
            name:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.name
                : documents.shipping_address.consumer_info?.name,
            surname:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.surname
                : documents.shipping_address.consumer_info?.surname,
            country:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.country
                : documents.shipping_address.consumer_info?.country,
            building:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.building
                : documents.shipping_address.consumer_info?.building,
            address:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.address
                : documents.shipping_address.consumer_info?.address,
            street_number:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.street_number
                : documents.shipping_address.consumer_info?.street_number,
            city:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.city
                : documents.shipping_address?.consumer_info?.city,
            zipcode:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.zipcode
                : documents.shipping_address.consumer_info?.zipcode,
            province:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.province
                : documents.shipping_address.consumer_info?.province,
            state:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.state
                : documents.shipping_address.consumer_info?.state,
            contact_email:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.contact_email
                : documents.shipping_address.consumer_info?.contact_email,
            contact_number:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.contact_number
                : documents.shipping_address?.consumer_info?.contact_number,
            address_notes:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.address_notes
                : documents.shipping_address?.consumer_info?.reference_name ??
                  documents.shipping_address?.consumer_info?.address_notes,
          })
        } catch (err) {
          context.dispatch(
            NotificationsActions.NOTIFY_ERROR,
            'pos_sale.error_restoring_sale',
            { root: true }
          )
        }
      }
    },
    [Actions.CHECK_TRANSACTION]: async (
      context: ActionContext<SalesState, RootState>
    ): Promise<bool> => {
      try {
        const sale = context.state.currentSale
        sale.capi?.map((d) => {
          if ('error_code' in d) {
            delete d.error_code
          }
        })
        if (
          Object.keys(sale).includes('id_transazione') &&
          typeof sale.id_transazione !== 'undefined'
        ) {
          const id = `${sale.cod_negozio},${sale.id_transazione}`
          const resp = await salesApi.apiV1SalesSaleIdCheckTransactionPatch(
            id,
            {
              data: {
                id,
                type: 'sale',
                attributes: context.state.currentSale,
              },
              preserve_decimals: true,
            }
          )
          if (resp.data?.errors?.length > 0) {
            resp.data?.errors.forEach((el) => {
              context.dispatch(NotificationsActions.NOTIFY_ERROR, el.detail, {
                root: true,
              })
            })
            return false
          }
          return true
        }
        return true
      } catch (error) {
        const errorResponse = error as ErrorD
        const errorMessage = errorResponse?.response?.data?.errors?.length
          ? extractErrorMessage(
              errorResponse?.response?.data?.errors[0]?.detail ?? ''
            )
          : 'pos_sale.sale_error'
        context.dispatch(NotificationsActions.NOTIFY_ERROR, errorMessage, {
          root: true,
        })
        return false
      }
    },
    [Actions.HYDRATE_SALES_SKUS]: async (
      context: ActionContext<SalesState, RootState>,
      capi:
        | ApiV1SalesDataAttributesCapi[]
        | ApiV1SalesSaleIdDataAttributesCapi[]
    ): Promise<void> => {
      const max_progress = Math.max(...capi.map((c) => c.progressivo ?? 0))
      const promises = capi.map(async (c) => {
        // Make sure to update the product progressive id so that the added
        // product and the saved one have the same id.
        await context.dispatch(Actions.ADD_SKU, c)
        context.commit(Mutations.STORE_PROGRESSIVO_CAPO, max_progress)
      })
      await Promise.allSettled(promises)
    },
    [Actions.SUSPEND_SALE]: async (
      context: ActionContext<SalesState, RootState>,
      preserveSale = false
    ): Promise<AxiosResponse<Sale> | undefined> => {
      const sale = context.state.currentSale
      const isSuspendedSale = typeof sale.id_transazione !== 'undefined'
      const id = isSuspendedSale
        ? `${sale.cod_negozio},${sale.id_transazione}`
        : undefined
      if (!preserveSale) {
        const pagamenti = context.state.currentSale
          .pagamenti as Array<ApiV1SalesDataAttributesPagamenti>
        const promises = pagamenti.map((p) => {
          if (
            p?.progressivo &&
            !store.state.payments.payments_validator[p.progressivo]
          ) {
            return store.dispatch(SalesActions.REMOVE_PAYMENT, p.progressivo)
          }
          return Promise.resolve()
        })
        await Promise.all(promises)
      }
      // Make sure to update totals before actually submitting the updated sale
      await context.dispatch(Actions.COMPUTE_TOTALS)
      context.commit(
        Mutations.UPDATE_SALE_STATUS,
        preserveSale ? CodiceStatoVendita.CURRENT : CodiceStatoVendita.SUSPENDED
      )
      // each capo needs to have the id_transazione field set to the id of the sale
      const enhancedCapi = context.state.currentSale.capi?.map((c) => {
        if ('layby_delete' in c) {
          delete c.layby_delete
        }
        if ('error_code' in c) {
          delete c.error_code
        }
        return {
          ...c,
          id_transazione: sale.id_transazione,
        }
      })
      const saleTrace: PatchSale = {
        preserve_decimals: true,
        sale_origin: ABSTRID_EA,
        data: {
          id,
          type: 'sales',
          attributes: {
            ...sale,
            capi: enhancedCapi,
          },
        },
      }
      try {
        let result: AxiosResponse<Sale>

        if (isSuspendedSale) {
          result = await salesApi.apiV1SalesSaleIdPatch(id as string, saleTrace)
        } else {
          result = await salesApi.apiV1SalesPost(saleTrace)
        }

        if (!preserveSale) {
          context.dispatch(Actions.INIT_SALE)
          context.dispatch(
            NotificationsActions.NOTIFY_SUCCESS,
            'pos_sale.successfully_suspended_sale',
            { root: true }
          )
        } else {
          if (result.data.data?.attributes?.id_transazione) {
            context.commit(
              Mutations.STORE_ID_TRANSAZIONE,
              result.data.data.attributes.id_transazione
            )
          }
          if (result.data.data?.attributes?.data_creazione) {
            context.commit(
              Mutations.STORE_CREATION_DATE,
              result.data.data.attributes.data_creazione
            )
          }
          return result
        }
      } catch (rawError) {
        const error = rawError as AxiosError

        const errorMessage = error?.response?.data?.errors?.length
          ? extractErrorMessage(error?.response?.data?.errors[0]?.detail)
          : ''

        const msg = i18n.global.t('pos_sale.error_suspending_sale')
        const toShow = errorMessage ? `${msg}\n${errorMessage}` : msg

        context.dispatch(NotificationsActions.NOTIFY_ERROR, toShow, {
          root: true,
        })
      }
    },
    [Actions.TRY_PAYMENT_CREDIT_CARD]: async (
      context: ActionContext<SalesState, RootState>
    ): Promise<AxiosResponse<Sale> | undefined> => {
      const sale = context.state.currentSale
      const isSuspendedSale = typeof sale.id_transazione !== 'undefined'
      const id = isSuspendedSale
        ? `${sale.cod_negozio},${sale.id_transazione}`
        : undefined
      await context.dispatch(Actions.COMPUTE_TOTALS)
      context.commit(Mutations.UPDATE_SALE_STATUS, CodiceStatoVendita.CURRENT)
      const enhancedCapi = context.state.currentSale.capi?.map((c) => {
        if ('layby_delete' in c) {
          delete c.layby_delete
        }
        if ('error_code' in c) {
          delete c.error_code
        }
        const dataCapi = { ...c, id_transazione: sale.id_transazione }
        return dataCapi
      })
      const saleTrace: PatchSale = {
        preserve_decimals: true,
        data: {
          id,
          type: 'sales',
          attributes: {
            ...sale,
            capi: enhancedCapi,
          },
        },
      }
      try {
        let result: AxiosResponse<Sale>

        if (isSuspendedSale) {
          result = await salesApi.apiV1SalesSaleIdPatch(id as string, saleTrace)
        } else {
          result = await salesApi.apiV1SalesPost(saleTrace)
        }
        if (result.data.data?.attributes?.id_transazione) {
          context.commit(
            Mutations.STORE_ID_TRANSAZIONE,
            result.data.data.attributes.id_transazione
          )
        }
        if (result.data.data?.attributes?.data_creazione) {
          context.commit(
            Mutations.STORE_CREATION_DATE,
            result.data.data.attributes.data_creazione
          )
        }
        return result
      } catch (rawError) {
        const error = rawError as AxiosError

        const errorMessage = error?.response?.data?.errors?.length
          ? extractErrorMessage(error?.response?.data?.errors[0]?.detail)
          : ''

        const msg = i18n.global.t('pos_common.error')
        const toShow = errorMessage ? `${msg}\n${errorMessage}` : msg

        context.dispatch(NotificationsActions.NOTIFY_ERROR, toShow, {
          root: true,
        })
      }
    },
    [Actions.ADD_SKU]: async (
      context: ActionContext<SalesState, RootState>,
      payload: ApiV1SalesDataAttributesCapi | ApiV1SalesSaleIdDataAttributesCapi
    ): Promise<boolean> => {
      // Try to do things using what we just read, eventually falling back to sku field.
      const sku = payload.sku_read || payload.sku
      const capiOldRfid =
        context.state.currentSale.capi?.filter(
          (c) =>
            c.rfid &&
            payload.rfid &&
            c.rfid === payload.rfid &&
            payload.rfid !== ''
        ) ?? []
      const rfid = payload.rfid
      if (capiOldRfid.length > 0) {
        context.commit(Mutations.STORE_SKU_ALERT_OPEN, true)
        modalController.open({
          type: ModalTypes.ERROR,
          component: '',
          title: i18n.global.t('pos_common.error'),
          message: i18n.global.t('pos_sale.item_added_more_times_rfid', {
            rfid,
            sku,
          }),
          confirmLabel: '',
          confirmActionButton: false,
          closedAction: () =>
            context.commit(Mutations.STORE_SKU_ALERT_OPEN, false),
        })
        return false
      }
      // const rfid = payload.rfid
      const pkConsumer = context.state.consumer?.pk_consumer ?? ''
      const variazioneImporto = payload.correzione_importo ?? 0

      try {
        const r = await catalogApi.apiV1PoswebModelsDataGet(
          sku,
          isConfigTruthy('SKU_GRUPPO_ENABLED') ? rfid : undefined,
          undefined,
          1,
          pkConsumer
        )
        if (r.data.data && r.data.data[0].attributes) {
          const p: GetSkuDataAttributes = {
            ...r.data.data[0].attributes,
            rfid,
          }

          // check if there are free alterations with textile only items in cart

          if (p.tipo_mov === TipiVendita.VENDITA_TESSUTO) {
            const extraTailorings =
              store.getters[SalesGetters.GET_EXTRA_TAILORING]
            const products = store.getters[SalesGetters.GET_CAPI]?.filter(
              (p) => p.codice_movimento === TipiVendita.VENDITA
            )

            if (products.length === 0 && extraTailorings.length !== 0) {
              openModalTailoringAlert({
                closedAction: async () => {
                  await store.dispatch(SalesActions.UPDATE_TAILORING, [])
                  await store.dispatch(
                    SalesActions.CONFIRM_TAILORING_ALTERATIONS
                  )
                },
                confirmButton: {
                  label: i18n.global.t('pos_common.proceed'),
                  icons: 'arrow-right',
                },
              })
            }
          }

          // it has to be UTC time in this specific format
          const creationDate = format(
            addMinutes(new Date(), new Date().getTimezoneOffset()),
            ISO8601DateTimeStandard
          )
          const preCustomData = JSON.parse(payload.custom_data || '{}')
          const tailoringDeliveryDate =
            preCustomData.data_consegna_sartoria || ''
          const lailoringNotes = preCustomData.nota_lavorazioni || ''

          if (context.state.currentSale.capi) {
            let customData = Object.assign(
              {},
              {
                desc_taglia: p.desc_taglia,
                desc_classe: p.desc_classe,
                variante: p.variante,
                annostag: p.annostag,
                modello: p.modello,
                data_consegna_sartoria: tailoringDeliveryDate,
                nota_lavorazioni: lailoringNotes,
                sconto_perc_attuale: p.tipo_importo
                  ? (
                      p.listini as Record<
                        string,
                        GetSkuDataAttributesListiniPriceType
                      >
                    )[p?.tipo_importo].perc_attuale
                  : 0,
              }
            )
            if (payload.custom_data) {
              customData = Object.assign(
                {},
                customData,
                JSON.parse(payload.custom_data)
              )
            }

            const orderCapi = context.state.currentSale.capi?.sort(
              (a, b) => (a?.progressivo ?? 0) - (b?.progressivo ?? 0)
            )
            const progressivo_capi =
              payload?.progressivo ?? orderCapi.length > 0
                ? (orderCapi[orderCapi?.length - 1]?.progressivo ?? 0) + 1
                : 1

            const newCapo = {
              // Make sure to copy (and/or overwrite) default data with what we have received from the hydration process.
              ...(payload || {}),
              classe: p?.classe,
              cod_negozio: context.rootState.configs.currentStore?.STORE_CODE,
              codice_movimento:
                payload.codice_movimento &&
                payload.codice_movimento === TipiVendita.LAYBY
                  ? payload.codice_movimento
                  : p.tipo_mov || TipiMovimenti.VENDITA,
              composizione: p.composizione,
              correzione_importo: variazioneImporto,
              custom_data: JSON.stringify(customData),
              ean: p.ean,
              flag_divisa:
                payload.flag_divisa &&
                payload.codice_movimento === TipiVendita.LAYBY
                  ? payload.flag_divisa
                  : p.flag_divisa,
              flag_promo:
                payload.flag_promo &&
                payload.codice_movimento === TipiVendita.LAYBY
                  ? payload.flag_promo
                  : p.flag_promo,
              importo_custom: payload.importo_custom
                ? payload.importo_custom
                : 0,
              importo_iniziale:
                payload.importo_iniziale &&
                payload.codice_movimento === TipiVendita.LAYBY
                  ? payload.importo_iniziale
                  : p.prezzo_iniziale ?? p.importo_finale,
              importo_finale:
                payload.importo_finale &&
                payload.codice_movimento === TipiVendita.LAYBY
                  ? payload.importo_finale
                  : (p.importo_finale ?? 0) + variazioneImporto,
              tipo_importo: p.tipo_importo,
              iva: p.iva,
              nome: p.nome,
              nota: '',
              progressivo: progressivo_capi,
              rfid: p.rfid,
              sconto: 0,
              sku_created: 0,
              sku_read: sku,
              sku_splitted: 0,
              sku_gruppo: p.sku_gruppo,
              tipologia_merce: p.tipologia_merce,
              codice_stato: context.state.currentSale.codice_stato,
              cod_commessa:
                payload.cod_commessa || context.state.selected_rdv?.venditrice,
              data_creazione: creationDate,
              sku: p.sku,
              error_code: p.error_code,
            }
            if (context.state.currentSale.id_transazione) {
              // FIXME: this should not be required: `id_transazione` should be a number (but it is actually a string)
              const id = parseInt(
                `${context.state.currentSale.id_transazione}`,
                10
              )
              newCapo.id_transazione = id
            }
            // Try to minimize the time available for a race condition to happen. By storing this value
            // as soon as possible, even multiple "concurrent" calls of this action will not result in
            // merged products details. If something bad happens during the execution of this action,
            // we will revert this value to the one previously stored.
            context.commit(
              Mutations.STORE_PROGRESSIVO_CAPO,
              payload.progressivo ?? progressivo_capi
            )
            context.commit(Mutations.STORE_PRODUCTS, {
              ...context.state.product_detail,
              [progressivo_capi]: {
                ...p,
                custom_data: customData,
              },
            })
            context.commit(Mutations.STORE_SALE_CAPI, [
              ...context.state.currentSale.capi,
              newCapo,
            ])
            context.dispatch(Actions.CHECK_DUPLICATE_SKU, p.sku)
            if (context.getters[Getters.GET_COUPON]) {
              context.dispatch(Actions.VALIDATE_COUPON)
            }
            await context.dispatch(Actions.CONFIRM_DISCOUNTS)
            await context.dispatch(Actions.COMPUTE_TOTALS)
            await context.dispatch(Actions.CHECK_PRODUCTS_STOCK)
          }
        }

        return true
      } catch (e: unknown) {
        const error = e as ErrorD
        context.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          error?.response?.data?.errors?.length
            ? error.response.data.errors[0]?.detail
            : 'pos_sale.barcode_scan_not_valid',
          { root: true }
        )
        return false
      }
    },
    [Actions.ADD_CAPO]: async (
      context: ActionContext<SalesState, RootState>,
      capo: ApiV1SalesDataAttributesCapi | ApiV1SalesSaleIdDataAttributesCapi
    ): Promise<void> => {
      const progressivo_capi = context.state.progressivo_capi + 1
      if (context.state.currentSale.capi) {
        context.commit(Mutations.STORE_PROGRESSIVO_CAPO, progressivo_capi)
        context.commit(Mutations.STORE_SALE_CAPI, [
          ...context.state.currentSale.capi,
          capo,
        ])
      }
      await context.dispatch(Actions.COMPUTE_TOTALS)
    },
    [Actions.REMOVE_CAPO]: async (
      context: ActionContext<SalesState, RootState>,
      progressivo: number
    ): Promise<void> => {
      if (context.state.currentSale.capi) {
        context.commit(
          Mutations.STORE_SALE_CAPI,
          context.state.currentSale.capi.filter(
            (c) => c.progressivo !== progressivo
          )
        )
        context.dispatch(Actions.RESET_CAPO_ALTERATIONS, progressivo)
        const tmpProduct_detail = context.state.product_detail
        delete tmpProduct_detail[progressivo]
        context.commit(Mutations.STORE_PRODUCTS, tmpProduct_detail)
      }
      if (context.getters[Getters.GET_COUPON]) {
        await context.dispatch(Actions.VALIDATE_COUPON)
      }

      await context.dispatch(Actions.CONFIRM_DISCOUNTS)
      await context.dispatch(Actions.COMPUTE_TOTALS)
      await context.dispatch(Actions.CHECK_PRODUCTS_STOCK)
    },
    [Actions.UPDATE_CAPO]: (
      context: ActionContext<SalesState, RootState>,
      capo: ApiV1SalesDataAttributesCapi | ApiV1SalesSaleIdDataAttributesCapi
    ): void => {
      if (context.state.currentSale.capi) {
        if (context.state.currentSale.capi) {
          context.commit(
            Mutations.STORE_SALE_CAPI,
            context.state.currentSale.capi.map((c) =>
              c.progressivo === capo.progressivo ? capo : c
            )
          )
        }
      }
      context.dispatch(Actions.COMPUTE_TOTALS)
    },
    [Actions.SET_CONSUMER]: async (
      { commit, state, dispatch }: ActionContext<SalesState, RootState>,
      payload: StoreConsumerPayload
    ): Promise<void> => {
      try {
        await store.dispatch(SalesActions.UPDATE_FULL_PAYMENT_DATA, {})
        await store.dispatch(SalesActions.UPDATE_FULL_PAYMENT_DATA, {
          paymentForm: {},
          paymentKey: PaymentKeys.CHEQUE,
        })
        commit(Mutations.STORE_CONSUMER, payload)
        store.dispatch(AddressValidationActions['reset-address-validation'])
        dispatch(Actions.RESET_DOCUMENTO_SPEDIZIONE)
        commit(Mutations.STORE_SALE, {
          ...state.currentSale,
          pk_consumer: payload.consumer?.pk_consumer,
        })

        /* const isAnonymous = store.state.consumers.anonymousConsumer.some(
          (ac) => ac.pk_consumer === payload.consumer?.pk_consumer
        )*/
        // TODO: 2023-10-11 ho spostato il controllo della privacy vedi task https://jira.mmfg.it/browse/FGABS-2329

        if (!state.consumer || !state.consumer.pk_consumer) {
          return
        }
        const usedCash =
          await consumerDataApi.apiV1ConsumersPkConsumerUsedCashGet(
            state.consumer.pk_consumer
          )
        if (!usedCash.data.data?.attributes) {
          return
        }
        const amount = usedCash.data.data.attributes.amount ?? 0
        commit(Actions.SET_USED_CASH, usedCash.data.data?.attributes)
        if (amount > 0) {
          const consumerFromUeMethod = ((consumer: GetConsumerAttributes) => {
            const consumerNazioneIso = consumer.nazione_iso
            const selectedNation = store.getters[
              GenericGetters.GET_NATIONS
            ].find(
              (nation: GetCountriesAttributes) =>
                nation.nazione_iso === consumerNazioneIso
            )

            return selectedNation
          })(state.consumer)
          const getCountryStore = store.getters[ConfigGetters.GET_COUNTRY_CODE]
          let message = ''
          const consumerCSameStore =
            consumerFromUeMethod?.nazione_iso === getCountryStore

          const soglia_extra_ue_aml =
            store.getters[ConfigGetters.GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_AML]
          const sogliaExtraStoreC =
            store.getters[
              ConfigGetters.GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_COUNTRY
            ]
          const sogliaStoreC =
            store.getters[ConfigGetters.GET_MAX_PAYABLE_CASH_AMOUNT]
          const remaing_amount_below_threshold = formatNumero(
            sogliaExtraStoreC - amount
          )
          const remaing_amount_over_threshold = formatNumero(
            soglia_extra_ue_aml - amount
          )
          const remaing_amount_below_threshold_store = formatNumero(
            sogliaStoreC - amount
          )
          const storeSign = store.state.configs.currentStore?.CURRENCY_SIGN
          const currencySign = store.state.configs.currentStore?.CURRENCY_SIGN
          if (consumerCSameStore) {
            if (amount < sogliaStoreC) {
              message = `${i18n.global.t(
                'pos_sale.max_money_amount_rules'
              )}:<br/>${i18n.global.t(
                'pos_sale.max_money_amount_accepted_for_consumer',
                {
                  c_sign: storeSign,
                  cur_sign: currencySign,
                  rim: remaing_amount_below_threshold_store,
                  soglia: formatNumero(sogliaStoreC),
                }
              )}`
            }
          } else {
            if (amount < sogliaExtraStoreC) {
              message = `${i18n.global.t(
                'pos_sale.max_money_amount_rules'
              )}:<br/>${i18n.global.t(
                'pos_sale.min_money_amount_foreign_consumer_with_data',
                {
                  c_sign: storeSign,
                  cur_sign: currencySign,
                  rim: remaing_amount_below_threshold,
                  soglia: formatNumero(sogliaExtraStoreC),
                  soglia_stra: formatNumero(soglia_extra_ue_aml),
                }
              )}`
            } else {
              message = `${i18n.global.t(
                'pos_sale.max_money_amount_rules'
              )}:<br/>${i18n.global.t(
                'pos_sale.max_money_amount_accepted_for_foreign_consumer',
                {
                  c_sign: storeSign,
                  cur_sign: currencySign,
                  rim: remaing_amount_over_threshold,
                  soglia_stra: formatNumero(soglia_extra_ue_aml),
                }
              )}`
            }
          }

          if (!payload.noAlert) {
            // XXX: modal
            modalController.open({
              name: 'max-money-amount__modal',
              type: ModalTypes.INFO,
              component: '',
              title: i18n.global.t('pos_common.attention'),
              message: message,
              confirmLabel: i18n.global.t('pos_common.continue'),
              confirmActionButton: true,
            })
          }
        }
      } catch (err: unknown) {
        const error = err as ErrorD
        await store.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          (error?.response?.data?.errors || [])[0].detail
        )
      }
    },
    [Actions.RESET_CONSUMER]: (
      context: ActionContext<SalesState, RootState>
    ) => {
      context.commit(Mutations.CLEAR_CONSUMER)
      context.commit(Mutations.CLEAR_USED_CASH)
      store.dispatch(AddressValidationActions['reset-address-validation'])
      context.dispatch(Actions.RESET_DOCUMENTO_SPEDIZIONE)
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        pk_consumer: '',
      })
    },
    [Actions.SET_SALE_DATI_PROMO]: (
      context: ActionContext<SalesState, RootState>,
      // TODO: change type
      payload: any
    ) => {
      context.commit(Mutations.UPDATE_SALE_DATA, payload)
    },
    [Actions.SET_CASHIER]: (
      context: ActionContext<SalesState, RootState>,
      cashier: GetCashiersAttributes
    ): void => {
      context.commit(Mutations.STORE_CASHIER, cashier)
      context.commit(Mutations.ADD_COD_COMMESSA, cashier.venditrice || '')
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        cod_cassiera: cashier.venditrice,
      })
      if (!context.state.selected_rdv) {
        context.commit(Mutations.STORE_RDV, cashier)
      }
    },
    [Actions.SET_MONEY_CHANGE]: (
      context: ActionContext<SalesState, RootState>,
      resto: number
    ): void => {
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        resto: resto,
      })
    },
    [Actions.RESET_CASHIER]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(Mutations.CLEAR_CASHIER)
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        cod_cassiera: undefined,
      })
    },
    [Actions.SET_RDV]: (
      context: ActionContext<SalesState, RootState>,
      cashier: GetCashiersAttributes
    ): void => {
      context.commit(Mutations.STORE_RDV, cashier)
    },
    [Actions.RESET_RDV]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(Mutations.CLEAR_RDV)
    },
    [Actions.SET_ANONYMOUS_CONSUMER]: (
      context: ActionContext<SalesState, RootState>,
      anonymousConsumer: string
    ): void => {
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        pk_consumer: anonymousConsumer,
      })
      context.commit(Mutations.CLEAR_CONSUMER)
    },
    [Actions.RESET_ANONYMOUS_CONSUMER]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        pk_consumer: undefined,
      })
      context.commit(Mutations.CLEAR_CONSUMER)
    },
    [Actions.SET_NOTA]: (
      context: ActionContext<SalesState, RootState>,
      nota: string
    ): void => {
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        nota,
      } as ApiV1SalesDataAttributes | ApiV1SalesSaleIdDataAttributes)
    },
    [Actions.RESET_NOTA]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        nota: '',
      } as ApiV1SalesDataAttributes | ApiV1SalesSaleIdDataAttributes)
    },
    [Actions.SET_ANONYMOUS]: (
      context: ActionContext<SalesState, RootState>,
      isAnonymous: boolean
    ): void => {
      context.commit(Mutations.CLEAR_CONSUMER)
      context.commit(Mutations.STORE_ANONYMOUS_FLAG, isAnonymous)
      if (isAnonymous) {
        context.dispatch(
          Actions.SET_ANONYMOUS_CONSUMER,
          AnonymousConsumers.SUPER_ANONYMOUS
        )
      }
    },
    [Actions.SET_PAYMENTS]: (
      context: ActionContext<SalesState, RootState>,
      payments:
        | ApiV1SalesDataAttributesPagamenti[]
        | ApiV1SalesSaleIdDataAttributesPagamenti[]
    ): void => {
      if (context.state.currentSale.pagamenti) {
        context.commit(Mutations.STORE_SALE_PAGAMENTI, payments)
        context.commit(
          Mutations.STORE_PRODUCTS,
          context.state.currentSale.pagamenti
            .map((p) => p.progressivo ?? 0)
            .reduce((v: { [key: string]: boolean }, p: number) => {
              v[p] = true
              return v
            }, {})
        )
      }
    },
    [Actions.RESET_PAYMENTS]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(Mutations.CLEAR_SALE_PAGAMENTI)
      store.commit(PaymentsMutations.CLEAR_VALIDATION_PAGAMENTI)
    },
    [Actions.RESET_ONLY_PAYMENTS_TYPE]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(Mutations.CLEAR_ONLY_PAYMENTS_TYPE)
      store.commit(PaymentsMutations.CLEAR_VALIDATION_PAGAMENTI)
    },
    [Actions.ADD_PAYMENT]: (
      context: ActionContext<SalesState, RootState>,
      pagamento:
        | ApiV1SalesDataAttributesPagamenti
        | ApiV1SalesSaleIdDataAttributesPagamenti
    ): void => {
      const progressivo_pagamenti = context.state.progressivo_pagamenti + 1
      pagamento.progressivo = progressivo_pagamenti
      const isTailoring =
        pagamento.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
        pagamento.codice_movimento ===
          TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO

      const isGiftCard = pagamento.cod_operazione === 'GIFT_CARD'
      let isCash: boolean | null = pagamento.cod_operazione === 'CONTANTI'
      let progressivo = pagamento.progressivo

      if (
        pagamento.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO ||
        pagamento.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO ||
        pagamento.codice_movimento === TipiMovimenti.CONTABILITA_COUPON ||
        (pagamento.progressivo_capo === 0 && isTailoring)
      ) {
        isCash = null
      } else if (pagamento.progressivo_capo !== 0 && isTailoring) {
        isCash = true
      }

      if (context.state.currentSale.id_transazione) {
        pagamento.id_transazione = context.state.currentSale.id_transazione
      }

      if (context.state.currentSale.pagamenti) {
        const extraTailoringAlreadyExisting =
          context.state.currentSale.pagamenti.filter((p) => {
            if (
              (p.codice_movimento !== TipiMovimenti.CONTABILITA_SARTORIA &&
                pagamento.codice_movimento !==
                  TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO) ||
              !p.dati_operazione ||
              !pagamento.dati_operazione
            ) {
              return false
            }

            if (
              JSON.parse(p.dati_operazione).classe ===
                JSON.parse(pagamento.dati_operazione).classe &&
              p.cod_operazione === pagamento.cod_operazione
            ) {
              return true
            }
          })

        if (
          extraTailoringAlreadyExisting.length === 0 ||
          pagamento.cod_operazione
        ) {
          context.commit(
            Mutations.STORE_PROGRESSIVO_PAGAMENTI,
            progressivo_pagamenti
          )
          context.commit(Mutations.STORE_SALE_PAGAMENTI, [
            ...context.state.currentSale.pagamenti,
            pagamento,
          ])
        } else {
          isCash = true
          progressivo =
            extraTailoringAlreadyExisting[0].progressivo ??
            pagamento.progressivo
        }
        store.commit(PaymentsMutations.STORE_VALIDATION_PAGAMENTI, {
          ...store.state.payments.payments_validator,
          [progressivo]: isTailoring || isGiftCard ? true : isCash,
        })
      }
    },
    [Actions.EDIT_PAYMENT]: (
      context: ActionContext<SalesState, RootState>,
      pagamento:
        | ApiV1SalesDataAttributesPagamenti
        | ApiV1SalesSaleIdDataAttributesPagamenti
    ): void => {
      if (context.state.currentSale.pagamenti) {
        context.commit(
          Mutations.STORE_SALE_PAGAMENTI,
          context.state.currentSale.pagamenti.map((p) => {
            return p.progressivo === pagamento.progressivo ? pagamento : p
          })
        )
      }
    },
    [Actions.REMOVE_PAYMENT]: (
      context: ActionContext<SalesState, RootState>,
      progressivo: number
    ): void => {
      if (context.state.currentSale.pagamenti) {
        context.commit(
          Mutations.STORE_SALE_PAGAMENTI,
          context.state.currentSale.pagamenti.filter((p) => {
            return p.progressivo !== progressivo
          })
        )
        const tmpPayments_validator = store.state.payments.payments_validator
        delete tmpPayments_validator[progressivo]
        store.commit(
          PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
          tmpPayments_validator
        )
      }
    },
    [Actions.ADD_PAYMENT_DATA]: (
      context: ActionContext<SalesState, RootState>,
      payload: {
        progressivo: number
        formData: string
        importo: number
        id_transazione: number
        importo_da_pagare: number
        giftCardBarcode: string | undefined
      }
    ): void => {
      if (context.state.currentSale.pagamenti) {
        const payments_list = context.state.currentSale.pagamenti?.map(
          (pagamento) => {
            let datiOperazione = ''
            if (pagamento.progressivo === payload.progressivo) {
              if (payload.giftCardBarcode) {
                datiOperazione =
                  payload.formData === ''
                    ? '{}'
                    : JSON.stringify({
                        dati_gift_card: {
                          barcode: payload.giftCardBarcode, // Aggiungi il barcode, dal payload
                          ...JSON.parse(payload.formData).dati_gift_card, // Parsa il formData attuale
                        },
                      })
              } else {
                datiOperazione =
                  payload.formData === '' ? '{}' : payload.formData
              }
              return {
                ...pagamento,
                dati_operazione: datiOperazione,
                importo_finale: payload.importo_da_pagare ?? payload.importo,
                importo_iniziale: payload.importo,
                id_transazione: payload.id_transazione,
              }
            }
            return pagamento
          }
        )
        context.commit(Mutations.STORE_SALE_PAGAMENTI, payments_list)
        store.commit(PaymentsMutations.STORE_VALIDATION_PAGAMENTI, {
          ...store.state.payments.payments_validator,
          [payload.progressivo]: true,
        })
      }
    },
    /**
     * Action made to store credit card amount without setting its validity to true
     * @param context
     * @param payload
     */
    [Actions.STORE_PAYMENT_AMOUNT]: (
      context: ActionContext<SalesState, RootState>,
      payload: { importo: number; progressivo: number }
    ): void => {
      context.commit(
        Mutations.STORE_SALE_PAGAMENTI,
        context.state.currentSale.pagamenti?.map((p) =>
          p.progressivo === payload.progressivo
            ? {
                ...p,
                dati_operazione: p.dati_operazione ? p.dati_operazione : '{}',
                importo_finale: payload.importo,
                importo_iniziale: payload.importo,
              }
            : p
        )
      )
    },
    [Actions.RESET_DISCOUNTS]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      const tmpPayments_validator = {
        ...store.state.payments.payments_validator,
      }
      if (context.state.currentSale.pagamenti) {
        const tmpPagamenti = context.state.currentSale.pagamenti.filter(
          (p) =>
            p.codice_movimento !== TipiMovimenti.CONTABILITA_SCONTO ||
            (p.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO &&
              tmpPayments_validator[String(p.progressivo)] !== null)
        )

        context.state.currentSale.pagamenti.forEach((p) => {
          if (p.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO) {
            if (tmpPayments_validator[String(p.progressivo)] === null) {
              delete tmpPayments_validator[String(p.progressivo)]
            } else {
              tmpPayments_validator[String(p.progressivo)] = false
            }
          }
        })

        context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
        store.commit(
          PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
          tmpPayments_validator
        )
      }
    },
    [Actions.CONFIRM_DISCOUNTS]: (
      context: ActionContext<SalesState, RootState>,
      deletion: false
    ): void => {
      if (context.state.currentSale.pagamenti) {
        const tmpPayments_validator = {
          ...store.state.payments.payments_validator,
        }

        const tmpPagamenti = context.state.currentSale.pagamenti.filter((p) => {
          if (p.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO) {
            if (
              (tmpPayments_validator[String(p.progressivo)] === null &&
                deletion) ||
              (tmpPayments_validator[String(p.progressivo)] === false &&
                !deletion)
            ) {
              delete tmpPayments_validator[String(p.progressivo)]
              return false
            }

            tmpPayments_validator[String(p.progressivo)] = true
          }
          return true
        })

        // UPDATE discounts calculating it for each article

        const articles = context.state.currentSale?.capi

        tmpPagamenti.forEach((payment) => {
          let discountsTotal = 0
          if (payment.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO) {
            articles.forEach((article) => {
              if (article.codice_movimento !== TipiVendita.SCARICO_OMAGGIO) {
                const pricetoCheck = article.importo_finale
                discountsTotal +=
                  (pricetoCheck *
                    Math.abs(
                      parseFloat(
                        JSON.parse(payment.dati_operazione).percentuale
                      )
                    )) /
                  100
              }
            })
            const discountsExtraTotalTailoring =
              (context.getters[Getters.GET_EXTRA_TAILORING_PRICE] *
                Math.abs(
                  parseFloat(JSON.parse(payment.dati_operazione).percentuale)
                )) /
              100
            const discountsTotalTailoring =
              (context.getters[Getters.GET_TAILORING_NO_EXTRA] *
                Math.abs(
                  parseFloat(JSON.parse(payment.dati_operazione).percentuale)
                )) /
              100
            const total_discount = roundDecimals(
              -Math.abs(
                discountsTotal +
                  discountsExtraTotalTailoring +
                  discountsTotalTailoring
              )
            )
            payment.importo_iniziale = total_discount
            payment.importo_finale = total_discount
          }
        })
        context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
        store.commit(
          PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
          tmpPayments_validator
        )
      }
    },
    [Actions.REMOVE_DISCOUNTS_VALIDATION]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      if (context.state.currentSale.pagamenti) {
        const tmpPayments_validator = {
          ...store.state.payments.payments_validator,
        }
        context.state.currentSale.pagamenti.forEach((p) => {
          if (p.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO) {
            tmpPayments_validator[String(p.progressivo)] = false
          }
        })
        store.commit(
          PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
          tmpPayments_validator
        )
      }
    },
    [Actions.REMOVE_REBATES_VALIDATION]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      if (context.state.currentSale.pagamenti) {
        const tmpPayments_validator = {
          ...store.state.payments.payments_validator,
        }
        context.state.currentSale.pagamenti.forEach((p) => {
          if (p.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO) {
            tmpPayments_validator[String(p.progressivo)] = false
          }
        })
        store.commit(
          PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
          tmpPayments_validator
        )
      }
    },
    [Actions.RESET_REBATES]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      const tmpPayments_validator = {
        ...store.state.payments.payments_validator,
      }
      if (context.state.currentSale.pagamenti) {
        const tmpPagamenti = context.state.currentSale.pagamenti.filter(
          (p) =>
            p.codice_movimento !== TipiMovimenti.CONTABILITA_ABBUONO ||
            (p.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO &&
              tmpPayments_validator[String(p.progressivo)] !== null)
        )

        context.state.currentSale.pagamenti.forEach((p) => {
          if (p.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO) {
            if (tmpPayments_validator[String(p.progressivo)] === null) {
              delete tmpPayments_validator[String(p.progressivo)]
            } else {
              tmpPayments_validator[String(p.progressivo)] = false
            }
          }
        })

        context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
        store.commit(
          PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
          tmpPayments_validator
        )
      }
    },
    [Actions.CONFIRM_REBATES]: (
      context: ActionContext<SalesState, RootState>,
      deletion = false
    ): void => {
      if (context.state.currentSale.pagamenti) {
        const tmpPayments_validator = {
          ...store.state.payments.payments_validator,
        }

        const tmpPagamenti = context.state.currentSale.pagamenti.filter((p) => {
          if (p.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO) {
            if (
              (tmpPayments_validator[String(p.progressivo)] === null &&
                deletion) ||
              (tmpPayments_validator[String(p.progressivo)] === false &&
                !deletion)
            ) {
              delete tmpPayments_validator[String(p.progressivo)]
              return false
            }

            tmpPayments_validator[String(p.progressivo)] = true
          }
          return true
        })
        context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
        store.commit(
          PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
          tmpPayments_validator
        )
      }
    },
    [Actions.RESET_TAILORING_ALTERATIONS]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      if (context.state.currentSale.pagamenti) {
        const salePayments = context.state.currentSale.pagamenti.filter(
          (payment: ApiV1SalesDataAttributesPagamenti) => {
            if (
              payment.progressivo_capo === 0 &&
              (payment.codice_movimento ===
                TipiMovimenti.CONTABILITA_SARTORIA ||
                payment.codice_movimento ===
                  TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO)
            ) {
              return false
            }
            return true
          }
        )
        context.commit(Mutations.STORE_SALE_PAGAMENTI, salePayments)
      }
    },
    [Actions.CONFIRM_TAILORING_ALTERATIONS]: async (
      context: ActionContext<SalesState, RootState>,
      deletion = false
    ): Promise<void> => {
      if (context.state.currentSale.pagamenti) {
        if (!deletion) {
          await context.dispatch(Actions.SET_TAILORING_VALIDATIONS_FALSE)
        }
        const tmpPayments_validator = {
          ...store.state.payments.payments_validator,
        }
        const tmpPagamenti = context.state.currentSale.pagamenti.filter((p) => {
          if (
            p.progressivo_capo === 0 &&
            (p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
              p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO)
          ) {
            if (
              (tmpPayments_validator[String(p.progressivo)] === null &&
                deletion) ||
              (tmpPayments_validator[String(p.progressivo)] === false &&
                !deletion)
            ) {
              delete tmpPayments_validator[String(p.progressivo)]
              return false
            }

            tmpPayments_validator[String(p.progressivo)] = true
          }
          return true
        })
        context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
        store.commit(
          PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
          tmpPayments_validator
        )
      }
    },
    [Actions.DELETE_UNCONFIRMED_EXTRA_SERVICES]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      if (context.state.currentSale.pagamenti) {
        const tmpPayments_validator = {
          ...store.state.payments.payments_validator,
        }
        context.state.currentSale.pagamenti.forEach((p) => {
          if (
            p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
            p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO
          ) {
            tmpPayments_validator[String(p.progressivo)] = false
          }
        })
        store.commit(
          PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
          tmpPayments_validator
        )
      }
    },
    [Actions.RESET_CAPO_ALTERATIONS]: (
      context: ActionContext<SalesState, RootState>,
      progressivo: number
    ) => {
      if (context.state.currentSale.pagamenti) {
        context.commit(
          Mutations.STORE_SALE_PAGAMENTI,
          context.state.currentSale.pagamenti.filter(
            (p) =>
              (p.codice_movimento !== TipiMovimenti.CONTABILITA_SARTORIA &&
                p.codice_movimento !==
                  TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO &&
                p.codice_movimento !== TipiMovimenti.CONTABILITA_SCONTO &&
                p.codice_movimento !== TipiMovimenti.CONTABILITA_ABBUONO) ||
              p.progressivo_capo !== progressivo
          )
        )
      }
    },
    [Actions.SET_DOCUMENTO_DATI]: (
      context: ActionContext<SalesState, RootState>,
      documento: FatturaForm | FatturaUEForm
    ): void => {
      if (context.state.currentSale.dati_documenti) {
        context.commit(Mutations.STORE_SALE, {
          ...context.state.currentSale,
          dati_documenti: JSON.stringify({
            ...JSON.parse(context.state.currentSale.dati_documenti),
            dati_documento: documento,
          }),
        })
      }
    },
    [Actions.SET_DOCUMENTO_SPEDIZIONE]: (
      context: ActionContext<SalesState, RootState>,
      documento: ShippingDetails
    ): void => {
      if (context.state.currentSale.dati_documenti) {
        context.commit(Mutations.STORE_SALE, {
          ...context.state.currentSale,
          dati_documenti: JSON.stringify({
            ...JSON.parse(context.state.currentSale.dati_documenti),
            ...documento,
          }),
        })
      }
    },
    [Actions.RESET_DOCUMENTO_SPEDIZIONE]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      if (
        context.state.currentSale.dati_documenti &&
        typeof context.state.currentSale.dati_documenti === 'string'
      ) {
        const dati_documenti = JSON.parse(
          context.state.currentSale.dati_documenti.toString()
        )
        delete dati_documenti?.shipping_address
        delete dati_documenti?.shipping_destination
        context.commit(Mutations.STORE_SALE, {
          ...context.state.currentSale,
          dati_documenti: JSON.stringify(dati_documenti),
        })
      }
    },
    [Actions.RESET_DOCUMENTO_DATI]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      if (context.state.currentSale.dati_documenti) {
        const dati_documenti = JSON.parse(
          context.state.currentSale.dati_documenti
        )
        delete dati_documenti.dati_documento
        context.commit(Mutations.STORE_SALE, {
          ...context.state.currentSale,
          dati_documenti: JSON.stringify(dati_documenti),
        })
      }
    },
    [Actions.SET_DOCUMENTO_CODICE]: (
      context: ActionContext<SalesState, RootState>,
      codDocumento: string
    ) => {
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        cod_documento: codDocumento,
      })
    },
    [Actions.RESET_DOCUMENTO_CODICE]: (
      context: ActionContext<SalesState, RootState>
    ) => {
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        cod_documento: undefined,
      })
    },
    [Actions.SET_FINAL_PRICE](
      context: ActionContext<SalesState, RootState>,
      price: number
    ): void {
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        prezzo_finale: price,
      })
    },
    async [Actions.UPDATE_TAILORING](
      { dispatch, state }: ActionContext<SalesState, RootState>,
      alterationsTabs: AlterationsTab[]
    ) {
      await dispatch(Actions.RESET_TAILORING_ALTERATIONS)
      if (!alterationsTabs) {
        alterationsTabs = []
      }
      let price = 0
      const convertedAlterations = alterationsTabs.reduce(
        (
          outputAlterations: GetAlterationPricesDataAttributes[],
          alterationTab: AlterationsTab,
          currentIndex: number
        ) => {
          const isReturn =
            alterationTab.cod_movimento === TipiVendita.STORNO ||
            alterationTab.cod_movimento === TipiVendita.RESO
          const currentTabAlterations = alterationTab.alterations
            .filter((alteration: AlterationsTabItem): boolean => {
              if (
                ('customPrice' in alteration &&
                  (typeof alteration.customType !== 'string' ||
                    alteration.customType.length < 5)) ||
                (alteration.attributes.progressivo === '99' &&
                  'customPrice' in alteration === false)
              ) {
                return false
              }

              return alteration.isSelected
            })
            .map(
              (
                alteration: AlterationsTabItem
              ): GetAlterationPricesDataAttributes => {
                if (alteration.customPrice) {
                  price = isReturn
                    ? Math.abs(alteration.customPrice) * -1
                    : Math.abs(alteration.customPrice)
                } else if (alteration.attributes.importo) {
                  price = isReturn
                    ? Math.abs(alteration.attributes.importo) * -1
                    : Math.abs(alteration.attributes.importo)
                } else {
                  price = 0
                }

                return {
                  classe: alteration.attributes.classe,
                  idAlterationTab: alterationTab.alterationGroupId,
                  cod_negozio: alteration.attributes.cod_negozio,
                  data_modifica: alteration.attributes.data_modifica,
                  additionalNotes: alterationTab.additionalNotes,
                  calendarValue: alterationTab.calendarValue,
                  descrizione:
                    alteration.customType && alteration.customType !== ''
                      ? alteration.customType
                      : alteration.attributes.descrizione,
                  importo: price,
                  importo_finale: alteration.hasPrice ? price : 0,
                  progressivo: alteration.attributes.progressivo,
                  gruppo: currentIndex + 1,
                  cod_movimento: alterationTab.cod_movimento,
                  vat: alterationTab.vat,
                }
              }
            )

          return outputAlterations.concat(currentTabAlterations)
        },
        [] as GetAlterationPricesDataAttributes[]
      )

      convertedAlterations.forEach(
        (alteration: GetAlterationPricesDataAttributes) => {
          dispatch(Actions.ADD_PAYMENT, {
            cod_negozio: alteration.cod_negozio,
            idAlterationTab: alteration.idAlterationTab,
            cod_operazione: alteration.progressivo,
            codice_movimento: ((): TipiMovimenti => {
              if (alteration.importo === 0 || alteration.importo_finale === 0) {
                return TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO
              }
              return TipiMovimenti.CONTABILITA_SARTORIA
            })(),
            dati_operazione: JSON.stringify({
              tipo: 'VENDITA',
              nota_lavorazioni: alteration.additionalNotes,
              data_consegna_sartoria: alteration.calendarValue,
              iva: alteration.vat
                ? alteration.vat
                : store.state.generic.vatCodes?.vat_percentage ?? 0,
              descrizione: alteration.descrizione,
              classe: alteration.classe,
              rdv: state.selected_rdv?.venditrice,
              gruppo:
                typeof alteration?.gruppo === 'number'
                  ? alteration?.gruppo.toString()
                  : alteration?.gruppo,
              cod_movimento: alteration.cod_movimento,
            }),
            divisa: undefined,
            id_postazione: undefined,
            importo_finale: alteration.importo_finale,
            importo_iniziale: alteration.importo,
            progressivo_capo: 0,
            tipo_applicazione: 'POSWEB',
          })
        }
      )
    },
    [Actions.COMPUTE_TOTALS]: (
      context: ActionContext<SalesState, RootState>
    ) => {
      if (
        context.state.currentSale.capi &&
        context.state.currentSale.pagamenti
      ) {
        const importoIniziale = context.state.currentSale.capi
          .map((c) => {
            return c.codice_movimento === TipiVendita.STORNO ||
              c.codice_movimento === TipiVendita.RESO
              ? Math.abs(c.importo_finale) * -1
              : c.importo_finale ?? 0
          })
          .reduce((t: number, i: number) => t + i, 0)

        const totalCapi = context.state.currentSale.capi
          .map((c) => {
            return c.codice_movimento === TipiVendita.STORNO ||
              c.codice_movimento === TipiVendita.RESO
              ? Math.abs(c.importo_finale) * -1
              : c.importo_finale ?? 0
          })
          .reduce((t: number, i: number) => t + i, 0)

        const totalSartorie = context.state.currentSale.pagamenti
          .filter(
            (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA
          )
          .map((c) => c.importo_finale ?? 0)
          .reduce((t: number, i: number) => t + i, 0)

        const totalDiscount = context.state.currentSale.pagamenti
          .filter(
            (p) =>
              p.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO ||
              p.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO ||
              p.codice_movimento === TipiMovimenti.CONTABILITA_COUPON ||
              p.codice_movimento === TipiMovimenti.CONTABILITA_PROMOZIONE
          )
          .map((c) => c.importo_finale ?? 0)
          .reduce((t: number, i: number) => t + i, 0)

        const totalGiftCard = context.state.currentSale.pagamenti
          .filter((p) => p.codice_movimento === TipiMovimenti.CONTABILITA_GIFT)
          .map((c) => c.importo_finale ?? 0)
          .reduce((t, i) => t + i, 0)

        const importo_finale = parseFloat(
          (totalCapi + totalDiscount + totalSartorie + totalGiftCard).toFixed(2)
        )
        const hasImportoChanged =
          importo_finale !== context.state.currentSale.importo_finale

        const _totalPagamenti = roundDecimals(
          context.state.currentSale.pagamenti
            .filter(
              (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_PAGAMENTO
            )
            .map((c) => c.importo_finale ?? 0)
            .reduce((t: number, i: number) => t + i, 0)
        )

        // Vendita omaggio
        if (
          context.state.currentSale.capi?.length > 0 &&
          totalCapi + totalDiscount === 0
        ) {
          context.commit(Mutations.STORE_SALE, {
            ...context.state.currentSale,
            codice_movimento: TipiVendita.SCARICO_OMAGGIO,
          })
        } else {
          const tipoVendita =
            context.state.currentSale.codice_movimento === TipiVendita.STORNO ||
            context.state.currentSale.codice_movimento === TipiVendita.RESO
              ? TipiVendita.STORNO
              : TipiVendita.VENDITA
          context.commit(Mutations.STORE_SALE, {
            ...context.state.currentSale,
            codice_movimento: tipoVendita,
          })
        }

        context.commit(Mutations.STORE_SALE, {
          ...context.state.currentSale,
          importo_iniziale: importoIniziale,
          importo_finale: importo_finale,
          importo_pagato: importo_finale,
        })

        if (!hasImportoChanged) {
          return
        }

        if (context.getters[Getters.GET_ONLY_PAYMENTS]?.length === 1) {
          const refPayment = context.getters[Getters.GET_ONLY_PAYMENTS][0]
          if (
            refPayment.cod_operazione === 'CARTA' &&
            store.state.payments.payments_validator[refPayment.progressivo]
          ) {
            return
          }
          context.dispatch(Actions.EDIT_PAYMENT, {
            ...refPayment,
            importo_iniziale: importo_finale,
            importo_finale: importo_finale,
          })
          context.commit(Mutations.STORE_SALE_IMPORTO_PAGATO, importo_finale)
        } else if (context.getters[Getters.GET_ONLY_PAYMENTS]?.length) {
          context.getters[Getters.GET_ONLY_PAYMENTS]?.forEach(
            (p: ApiV1SalesDataAttributesPagamenti) => {
              if (
                p.cod_operazione !== 'CARTA' ||
                (p.progressivo &&
                  !store.state.payments.payments_validator[p.progressivo])
              ) {
                context.dispatch(Actions.EDIT_PAYMENT, {
                  ...p,
                  importo_iniziale: 0,
                  importo_finale: 0,
                })
              }
            }
          )
        }
      }
    },
    [Actions.CHECK_DUPLICATE_SKU]: (
      context: ActionContext<SalesState, RootState>,
      sku: string
    ) => {
      if (!sku || context.state.skuAlertOpen) {
        return
      }
      const capiDoppi =
        context.state.currentSale.capi?.filter((c) => c.sku === sku) || []
      if (capiDoppi?.length > 1) {
        context.commit(Mutations.STORE_SKU_ALERT_OPEN, true)
        modalController.open({
          type: ModalTypes.INFO,
          component: '',
          title: i18n.global.t('pos_common.attention'),
          message: i18n.global.t('pos_sale.item_added_more_times'),
          confirmLabel: '',
          confirmActionButton: false,
          closedAction: () =>
            context.commit(Mutations.STORE_SKU_ALERT_OPEN, false),
        })
      }
    },
    [Actions.UPDATE_PRODUCT_DETAIL]: (
      context: ActionContext<SalesState, RootState>,
      capo: ApiV1SalesDataAttributesCapi
    ) => {
      store.commit(SalesMutations.UPDATE_PRODUCT_DETAIL, capo)
    },
    [Actions.UPDATE_PAYMENT_VALIDATION]: (
      context: ActionContext<SalesState, RootState>,
      payload: { progressivo: string; value: boolean }
    ) => {
      store.commit(PaymentsMutations.STORE_VALIDATION_PAGAMENTI, {
        ...store.state.payments.payments_validator,
        [payload.progressivo]: payload.value,
      })
    },
    [Actions.CHECK_COUPON]: async (
      context: ActionContext<SalesState, RootState>,
      couponCode: string
    ): Promise<void> => {
      const payload = {
        ...context.state.currentSale,
        pagamenti: context.state.currentSale.pagamenti?.filter(
          (p) =>
            p.progressivo &&
            store.state.payments.payments_validator[p?.progressivo] !== false
        ),
      }

      try {
        const r = await couponsApi.apiV1CouponsCouponCodeCheckPost(couponCode, {
          data: {
            attributes: payload as ApiV1CouponsCouponCodeCheckDataAttributes,
          },
        })

        if (r.data.data?.status) {
          const coupons = context.state.currentSale.pagamenti?.filter(
            (c) => c.codice_movimento === TipiMovimenti.CONTABILITA_COUPON
          )
          if (coupons) {
            const promises = coupons.map((coupon) =>
              context.dispatch(Actions.DELETE_COUPON, coupon.progressivo)
            )
            await Promise.all(promises)
          }

          try {
            await context.dispatch(Actions.VALIDATE_COUPON, r.data.data)
            return Promise.resolve()
          } catch (e) {
            return Promise.reject(e)
          }
        } else {
          context.dispatch(
            NotificationsActions.NOTIFY_ERROR,
            'pos_sale.coupon_inactive',
            { root: true }
          )
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject()
        }
      } catch (e) {
        context.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          typeof e === 'string'
            ? extractErrorMessage(e)
            : i18n.global.t('pos_sale.coupon_code_invalid'),
          { root: true }
        )
        // @TODO fix error hendling
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
    },
    [Actions.VALIDATE_COUPON]: (
      context: ActionContext<SalesState, RootState>,
      coupon?: CouponData
    ): Promise<void> => {
      return new Promise<void>((res, rej) => {
        let payload: PostCoupon1
        let paymentCoupon: ApiV1SalesDataAttributesPagamenti
        if ('layby' in context.state.currentSale) {
          context.dispatch(Actions.RESET_LAYBY_DATA)
        }
        if (coupon) {
          const items: { [k: string]: number } = {}
          if (coupon.items?.length) {
            coupon.items?.forEach((r) => {
              if (r.item_code && r.value) {
                items[r.item_code] = r.value
              }
            })
          }
          const totalDiscount = coupon.items?.length
            ? coupon.items?.map((i) => i.value ?? 0).reduce((p, c) => p + c, 0)
            : 0
          paymentCoupon = {
            codice_movimento: TipiMovimenti.CONTABILITA_COUPON,
            tipo_applicazione: 'POSWEB',
            progressivo_capo: 0,
            barcode: '',
            nota: '',
            dati_operazione: JSON.stringify({
              info: {
                importo: coupon.value,
                tipo: coupon.coupon_type,
                stato: 'valido',
                coupon: coupon.id,
                message: '',
              },
              items,
              code: coupon.id,
            }),
            importo_finale: -totalDiscount,
            codice_stato: context.state.currentSale.codice_stato,
            cod_operazione: '',
            importo_iniziale: -totalDiscount,
            id_postazione: context.state.currentSale.id_postazione_apertura,
            cod_negozio: context.state.currentSale.cod_negozio,
            divisa: context.state.currentSale.divisa,
          }
          payload = {
            data: {
              attributes: {
                ...(context.state
                  .currentSale as ApiV1CouponsCouponCodeCheckDataAttributes),
                pagamenti: context.state.currentSale?.pagamenti?.length
                  ? [
                      ...context.state.currentSale.pagamenti.filter(
                        (p) =>
                          p.progressivo &&
                          store.state.payments.payments_validator[
                            p?.progressivo
                          ] !== false
                      ),
                      paymentCoupon,
                    ]
                  : [paymentCoupon],
              },
            },
          }
        } else {
          payload = {
            data: {
              attributes: context.state
                .currentSale as ApiV1CouponsCouponCodeCheckDataAttributes,
            },
          }
        }
        couponsApi
          .apiV1CouponsValidateSalePost(payload)
          .then(async (r) => {
            if (r.data.data?.status === 'ERROR') {
              // coupon is not valid - if coupon was inserted, it will be removed
              context.commit(Mutations.SET_GIFT_CARD_SAVE_IN_PROCESS, false)
              context.dispatch(
                NotificationsActions.NOTIFY_ERROR,
                extractErrorMessage(r.data.data.error ?? ''),
                { root: true }
              )
              await context.dispatch(
                Actions.REMOVE_PAYMENT,
                context.state.currentSale.pagamenti?.find(
                  (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_COUPON
                )?.progressivo
              )
              // @TODO fix error handling
              // eslint-disable-next-line prefer-promise-reject-errors
              rej()
            } else if (coupon && paymentCoupon) {
              // if this was called with a coupon as an argument, it needs to be added to payments
              context.dispatch(Actions.ADD_PAYMENT, paymentCoupon)
              res()
            } else {
              // update coupon value if new items were added
              const totalDiscount = r.data.data?.items?.length
                ? r.data.data.items
                    .map((i) => i.value ?? 0)
                    .reduce((p, c) => p + c, 0)
                : 0
              context.dispatch(Actions.UPDATE_COUPON_VALUE, -totalDiscount)
              res()
            }
          })
          .catch((e) => {
            context.dispatch(
              NotificationsActions.NOTIFY_ERROR,
              extractErrorMessage(e),
              { root: true }
            )
            // @TODO fix error handling
            // eslint-disable-next-line prefer-promise-reject-errors
            rej()
          })
      })
    },
    [Actions.CONFIRM_COUPON]: (
      context: ActionContext<SalesState, RootState>,
      deletion: boolean
    ) => {
      if (context.state.currentSale.pagamenti) {
        const tmpPayments_validator = {
          ...store.state.payments.payments_validator,
        }

        const tmpPagamenti = context.state.currentSale.pagamenti.filter((p) => {
          if (p.codice_movimento === TipiMovimenti.CONTABILITA_COUPON) {
            if (
              (tmpPayments_validator[String(p.progressivo)] === null &&
                deletion) ||
              (tmpPayments_validator[String(p.progressivo)] === false &&
                !deletion)
            ) {
              delete tmpPayments_validator[String(p.progressivo)]
              return false
            }
            tmpPayments_validator[String(p.progressivo)] = true
          }
          return true
        })
        context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
        store.commit(
          PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
          tmpPayments_validator
        )
      }
    },
    [Actions.DELETE_COUPON]: async (
      context: ActionContext<SalesState, RootState>,
      progressivo: number
    ) => {
      if (context.state.currentSale.pagamenti) {
        await context.dispatch(Actions.REMOVE_PAYMENT, progressivo)
        const tmpValidator = store.state.payments.payments_validator
        delete tmpValidator[progressivo]
        store.commit(PaymentsMutations.STORE_VALIDATION_PAGAMENTI, tmpValidator)
      }
    },
    [Actions.UPDATE_COUPON_VALUE]: (
      context: ActionContext<SalesState, RootState>,
      amount: number
    ) => {
      if (
        context.state.currentSale.pagamenti &&
        context.getters[Getters.GET_COUPON]
      ) {
        const tmpPagamenti = [
          ...context.state.currentSale.pagamenti.filter(
            (p) =>
              p.progressivo !== context.getters[Getters.GET_COUPON].progressivo
          ),
          {
            ...context.getters[Getters.GET_COUPON],
            importo_iniziale: amount,
            importo_finale: amount,
          },
        ]
        context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
      }
    },
    [Actions.SET_TAILORING_VALIDATIONS_FALSE]: (
      context: ActionContext<SalesState, RootState>
    ) => {
      if (context.state.currentSale.pagamenti) {
        const tailoring = context.state.currentSale.pagamenti.filter(
          (p) =>
            p.progressivo_capo === 0 &&
            (p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
              p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO)
        )
        const tmpPayments_validator = store.state.payments.payments_validator
        tailoring.forEach((t) => {
          if (t.progressivo && tmpPayments_validator[t.progressivo] === null) {
            tmpPayments_validator[t.progressivo] = false
          }
        })
        store.commit(
          PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
          tmpPayments_validator
        )
      }
    },
    [Actions.SET_DATA_VISIBILITY]: async (
      context: ActionContext<SalesState, RootState>
    ): Promise<void> => {
      const [statisticsResponse, statisticsInsegnaResponse] = await Promise.all(
        [
          consumerDataApi.apiV1ConsumersPkConsumerStatisticsGet(
            context.getters[Getters.GET_CONSUMER_ID]
          ),
          consumerDataApi.apiV1ConsumersPkConsumerStatisticsInsegnaGet(
            context.getters[Getters.GET_CONSUMER_ID]
          ),
        ]
      )

      const payload = {
        statistics: statisticsResponse.data,
        statisticsInsegna: statisticsInsegnaResponse.data,
      } as Statistics

      context.commit(Mutations.STORE_DATA_VISIBILITY, payload)
    },
    [Actions.ACTIVATE_GIFT_CARDS]: async (
      context: ActionContext<SalesState, RootState>,
      giftCards: GiftCardForm[]
    ): Promise<void> => {
      context.commit(Mutations.SET_GIFT_CARD_SAVE_IN_PROCESS, true)
      const payload: GiftCards = {
        data: giftCards
          .filter((g) => !g.activated && g.amount && g.barcode?.length)
          ?.map((g) => ({
            type: 'gift_card',
            attributes: {
              barcode: g.barcode,
              row_num: g.row_num,
              importo: g.amount,
            },
          })),
      }
      if (!payload.data?.length) {
        context.commit(Mutations.SET_GIFT_CARD_SAVE_IN_PROCESS, false)
        return Promise.resolve()
      }
      let idTransazioneGenerated = false
      if (!context.state.currentSale.id_transazione) {
        await context.dispatch(Actions.GET_NEW_TRANSACTION_ID, true)
        idTransazioneGenerated = true
      }
      try {
        const result = await giftCardApi.apiV1GiftCardsActivateSaleIdPost(
          `${context.rootState.configs.currentStore?.STORE_CODE},${String(
            context.state.currentSale.id_transazione
          )}`,
          payload
        )
        ;(
          result.data.data as {
            attributes: {
              status: string
              message: string
              importo: number
              barcode: string
              data: { [k: string]: string }
            }
          }[]
        )?.forEach((g) => {
          if (g.attributes.status === 'error') {
            context.dispatch(
              NotificationsActions.NOTIFY_ERROR,
              g.attributes.message,
              { root: true }
            )
            if (idTransazioneGenerated) {
              context.dispatch(Actions.CLEAR_TRANSACTION_ID, true)
            }
            return Promise.reject(new Error('status === error'))
          } else if (g.attributes.status === 'created') {
            idTransazioneGenerated = false
            context.dispatch(Actions.ADD_PAYMENT, {
              id_transazione: context.state.currentSale.id_transazione,
              cod_negozio: context.rootState.configs.currentStore?.STORE_CODE,
              codice_stato: context.state.currentSale.codice_stato,
              progressivo_capo: 0,
              codice_movimento: TipiMovimenti.CONTABILITA_GIFT,
              cod_operazione: 'GIFT_CARD',
              dati_operazione: JSON.stringify({
                iva: 0,
                dati_gift_card: g.attributes.data,
              }),
              importo_iniziale: g.attributes.importo,
              importo_finale: g.attributes.importo,
              divisa: context.rootState.configs.currentStore?.CURRENCY_CODE,
              nota: '',
              barcode: g.attributes.barcode,
              reso: 0, // todo RESI
              data_creazione: format(new Date(), ISO8601DateTimeStandard),
              data_modifica: format(new Date(), ISO8601DateTimeStandard),
              tipo_applicazione: 'POSWEB',
              id_postazione: context.rootState.configs.setup?.cash_id,
            })
          }
        })
        context.commit(Mutations.SET_GIFT_CARD_SAVE_IN_PROCESS, false)
        return Promise.resolve()
      } catch (err: any) {
        if (err?.response?.data?.errors?.length) {
          await store.dispatch(
            NotificationsActions.NOTIFY_ERROR,
            extractErrorMessage(
              err?.response?.data?.errors[0].detail?.message || ''
            ),
            { root: true }
          )
        }
        context.commit(Mutations.SET_GIFT_CARD_SAVE_IN_PROCESS, false)
        return Promise.reject(err)
      }
    },
    [Actions.GET_NEW_TRANSACTION_ID]: async (
      context: ActionContext<SalesState, RootState>,
      preserveSale = false
    ): Promise<string | undefined> => {
      try {
        const idTransazione = await getNewTransactionId()
        if (idTransazione) {
          context.commit(Mutations.STORE_ID_TRANSAZIONE, idTransazione)
          context.commit(
            Mutations.STORE_CREATION_DATE,
            format(new Date(), ISO8601DateTimeStandard)
          )
        }
        return idTransazione
      } catch (rawError) {
        const error = rawError as AxiosError

        if (!preserveSale) {
          context.dispatch(
            NotificationsActions.NOTIFY_ERROR,
            'pos_sale.error_new_transaction_sale',
            { root: true }
          )
        } else {
          const msg =
            i18n.global.t('pos_sale.error_new_transaction_sale') +
            (error?.response?.data?.errors?.length
              ? '\n' +
                extractErrorMessage(error?.response?.data?.errors[0]?.detail)
              : '')
          context.dispatch(NotificationsActions.NOTIFY_ERROR, msg, {
            root: true,
          })
        }
      }
    },
    [Actions.CLEAR_TRANSACTION_ID]: async (
      context: ActionContext<SalesState, RootState>,
      preserveSale = false
    ): Promise<string | undefined> => {
      try {
        await context.commit(Mutations.CLEAR_ID_TRANSAZIONE)
      } catch (rawError) {
        const error = rawError as AxiosError

        if (!preserveSale) {
          context.dispatch(
            NotificationsActions.NOTIFY_ERROR,
            'pos_sale.error_new_transaction_sale',
            { root: true }
          )
        } else {
          const msg =
            i18n.global.t('pos_sale.error_new_transaction_sale') +
            (error?.response?.data?.errors?.length
              ? '\n' +
                extractErrorMessage(error?.response?.data?.errors[0]?.detail)
              : '')
          context.dispatch(NotificationsActions.NOTIFY_ERROR, msg, {
            root: true,
          })
        }
      }
    },
    /**
     * Actions.PAY_GIFT_CARD asincrona per effettuare un pagamento con una carta regalo.
     * Usata alla conferma della Gift nella spalla pagamenti
     * accetta:
     * @param context
     * @param payload oggetto con informazioni sulla carta regalo, l'importo del pagamento e il progressivo.
     * restituisce una Promise vuota.
     * effetti:
     * • API call: salesApi.apiV1SalesSaleIdPaymentsGiftCardGiftCodePost / salesApi.apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch
     * • action.ADD_PAYMENT_DATA
     * • mutation.STORE_ID_TRANSAZIONE
     * • mutation.STORE_CREATION_DATE
     * • mutation.GIFTCARD_PAYMENT_ONGOING
     */
    [Actions.PAY_GIFT_CARD]: async (
      context: ActionContext<SalesState, RootState>,
      payload: {
        giftCard: GiftCardInformationDataAttributes
        amount: number
        progressivo: number
      }
    ): Promise<void> => {
      const idTransazione = context.state.currentSale.id_transazione || 0

      // Costruisci l'id utilizzando il codice del negozio corrente e l'id della transazione.
      const saleId = `${
        context.rootState.configs.currentStore?.STORE_CODE
      },${String(idTransazione)}`
      const payment = context.state.currentSale.pagamenti?.find(
        (p) => p.progressivo === payload.progressivo
      )
      if (!payment) {
        return
      }
      context.state.currentSale.capi?.map((d) => {
        if ('error_code' in d) {
          delete d.error_code
        }
      })
      // Costruisci l'oggetto "burnGiftCard" per effettuare il pagamento
      // inglobando context.state.currentSale.
      // passato nel try, più sotto
      const burnGiftCard: BurnGiftCard = {
        data: {
          type: 'burn_gift_card',
          id: saleId,
          attributes: {
            importo: payload.amount,
            barcode: payload.giftCard.CardCode,
            is_store_credit: 0, // if type === STORE_CREDIT_CORPORATE then 1
            ...context.state.currentSale,
            pagamenti: [payment],
            id_transazione: idTransazione,
            om_num_order: undefined,
          },
        },
      }
      // handleResult prepara il risultato del pagamento, ottenuto dalla salesApiCall
      // più sotto nel try
      const handleResult = (r: AxiosResponse<ReturnBurnGiftCard>) => {
        const responseData = r.data.data

        if (!responseData || responseData.length === 0) {
          return
        }
        const esito = (responseData[1] as GiftCardData)
          ?.attributes as GiftCardData1DataAttributes & {
          message?: string
        }
        if (esito?.Status === 'ERROR') {
          context.commit(
            Mutations.STORE_ID_TRANSAZIONE,
            (responseData[0] as ApiV1SalesData)?.attributes?.id_transazione
          )
          context.commit(
            Mutations.STORE_CREATION_DATE,
            (responseData[0] as ApiV1SalesData)?.attributes?.data_creazione
          )
          store.commit(GiftMutations.GIFTCARD_PAYMENT_ONGOING, false)
          context.dispatch(NotificationsActions.NOTIFY_ERROR, esito?.message, {
            root: true,
          })
        } else {
          // Aggiorna lo stato della transazione e aggiungi i dati del pagamento.
          const saleResult = responseData[0] as ManageSaleReturnData
          store.commit(GiftMutations.GIFTCARD_PAYMENT_ONGOING, false)
          context.commit(
            Mutations.STORE_ID_TRANSAZIONE,
            saleResult?.attributes?.id_transazione
          )
          const payments = saleResult.attributes?.pagamenti

          const gift = payments?.length ? payments[payments?.length - 1] : {}
          if (!gift) {
            return
          }
          context.commit(Mutations.STORE_CREATION_DATE, gift.data_creazione)
          // Aggiungi i dati del pagamento alla lista del currentSale.pagamenti.
          context.dispatch(Actions.ADD_PAYMENT_DATA, {
            progressivo: payload.progressivo,
            formData: gift.dati_operazione,
            importo: gift.importo_iniziale,
            id_transazione: gift.id_transazione,
            giftCardBarcode: payload.giftCard.CardCode,
          })
        }
      }
      // esegui il pagamento
      try {
        const salesApiCall =
          idTransazione === 0
            ? salesApi.apiV1SalesSaleIdPaymentsGiftCardGiftCodePost
            : salesApi.apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch

        const result = await salesApiCall(
          saleId,
          payload.giftCard.CardCode || '',
          burnGiftCard
        )
        // Gestisci la risposta
        handleResult(result)
      } catch (error: unknown) {
        await store.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          error.response.data.errors[0].detail
        )
      }
    },
    [Actions.UPDATE_SUSPENDED_CREDIT]: (
      context: ActionContext<SalesState, RootState>,
      payload: SuspendedCreditForm
    ) => {
      context.commit(Mutations.UPDATE_SUSPENDED_CREDIT, payload)
    },
    [Actions.FETCH_SALES_HEADER_ICONS]: async (
      context: ActionContext<SalesState, RootState>
    ) => {
      const pkConsumer = context.state.consumer?.pk_consumer ?? ''
      const filterDataA = store.getters[ConfigGetters.GET_CUSTOM_DATE]
        ? store.getters[ConfigGetters.GET_CUSTOM_DATE]
        : undefined

      const response = await consumerDataApi.apiV1ConsumersIconsPkConsumerGet(
        pkConsumer,
        filterDataA
      )

      const icons = response?.data?.data?.attributes
      context.commit(Mutations.SET_SALES_HEADER_ICONS, icons)
    },
    [Actions.UPDATE_PAYMENT_DATA]: (
      context: ActionContext<SalesState, RootState>,
      payload: { changed: { id: string; value: string }; paymentKey: string }
    ) => {
      context.commit(Mutations.UPDATE_PAYMENT_DATA, payload)
    },
    [Actions.UPDATE_FULL_PAYMENT_DATA]: (
      context: ActionContext<SalesState, RootState>,
      payload: { paymentForm: PaymentMethodForm; paymentKey: string }
    ) => {
      context.commit(Mutations.UPDATE_FULL_PAYMENT_DATA, payload)
    },
    [Actions.CLOSE_SALE]: async (
      context: ActionContext<SalesState, RootState>,
      payload: {
        invoiceIsValid: boolean
        datiDocumenti:
          | SaleTicketDocument
          | FatturaForm
          | FatturaUEForm
          | FatturaAmbForm
          | FatturaTaxfreeForm
        isInDepositFlow: boolean
      }
    ): Promise<boolean> => {
      const selectedDocument = context.state.currentSale.cod_documento
      const allowedDocuments = [
        TipiDocumento.SALE_TICKET,
        TipiDocumento.SALE_INVOICE,
        TipiDocumento.SALE_INVOICE_EU,
        TipiDocumento.SALE_INVOICE_AMB,
        TipiDocumento.SALE_TAXFREE,
      ]
      if (
        payload.invoiceIsValid &&
        allowedDocuments.includes(selectedDocument as TipiDocumento)
      ) {
        await store.dispatch(
          SalesActions.SET_DOCUMENTO_DATI,
          payload.datiDocumenti
        )
      }
      let saleRegistrationIsOk
      if (payload.isInDepositFlow) {
        await store.dispatch(SalesActions.REMOVE_CAPI_LAYBY_DELETE)
        saleRegistrationIsOk = await store.dispatch(SalesActions.CREATE_SALE)
        store.dispatch(SalesActions.SET_LAYBY_STATUS, LaybyStatusType.RECAP)
        return saleRegistrationIsOk
      }
      if (store.getters[SalesGetters.IS_FREE_RETURN]) {
        await store.dispatch(SalesActions.SET_DATI_STORNO_CURRENTSALE)
      }

      const data_creazione = store.getters[ConfigGetters.GET_CUSTOM_DATE]
        ? dateToISODayWithTime(
            unformatMMFGDate(store.getters[ConfigGetters.GET_CUSTOM_DATE])
          )
        : dateToISODayWithTime(new Date())
      await store.dispatch(SalesActions.STORE_CREATION_DATE, data_creazione)

      if (
        Object.keys(context.state.currentSale).includes('id_transazione') &&
        typeof context.state.currentSale.id_transazione !== 'undefined'
      ) {
        saleRegistrationIsOk = await store.dispatch(SalesActions.UPDATE_SALE)
        return saleRegistrationIsOk
      }
      store.dispatch(SalesActions.UPDATE_FULL_PAYMENT_DATA, {
        paymentForm: {},
        paymentKey: PaymentKeys.CHEQUE,
      })
      store.dispatch(SalesActions.UPDATE_FULL_PAYMENT_DATA, {
        paymentForm: {},
        paymentKey: PaymentKeys.CASH,
      })
      saleRegistrationIsOk = await store.dispatch(SalesActions.CREATE_SALE)
      return saleRegistrationIsOk
    },
    [Actions.SET_CART_TYPE]: (
      context: ActionContext<SalesState, RootState>,
      payload: CartType
    ) => {
      context.commit(Mutations.SET_CART_TYPE, payload)
    },
    [Actions.SET_SELECTED_CART_TYPE]: (
      context: ActionContext<SalesState, RootState>,
      payload: CartType
    ) => {
      context.commit(Mutations.RESET_LAYBY_DATA)
      context.commit(Mutations.SET_SELECTED_CART_TYPE, payload)
    },
    [Actions.RESET_CART_TYPE]: (
      context: ActionContext<SalesState, RootState>
    ) => {
      context.commit(Mutations.RESET_CART_TYPE)
    },
    [Actions.SET_LAYBY_INFO]: (
      context: ActionContext<SalesState, RootState>,
      payload: { [key: string]: string | number }
    ) => {
      context.commit(
        Mutations.SET_LAYBY_INFO,
        Object.assign({}, context.state.laybyInfo, payload)
      )
    },
    [Actions.SET_LAYBY_STATUS]: (
      context: ActionContext<SalesState, RootState>,
      payload: LaybyStatusType
    ) => {
      context.commit(Mutations.SET_LAYBY_STATUS, payload)
    },
    [Actions.SET_TOTAL_PRICE_DEPOSIT]: (
      context: ActionContext<SalesState, RootState>,
      payload: number
    ) => {
      context.commit(Mutations.SET_TOTAL_PRICE_DEPOSIT, payload)
    },
    [Actions.SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD]: (
      context: ActionContext<SalesState, RootState>,
      payload: number
    ) => {
      context.commit(Mutations.SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD, payload)
    },
    [Actions.SET_STORE_SALE_LAYBY]: (
      context: ActionContext<SalesState, RootState>
    ) => {
      context.commit(Mutations.SET_STORE_SALE_LAYBY, context.state?.laybyInfo)
    },
    [Actions.RESET_LAYBY]: (context: ActionContext<SalesState, RootState>) => {
      context.commit(Mutations.RESET_LAYBY)
    },
    [Actions.RESET_LAYBY_DATA]: (
      context: ActionContext<SalesState, RootState>
    ) => {
      context.commit(Mutations.RESET_LAYBY_DATA)
    },
    [Actions.SET_LAYBY_CONSUMER_LIST]: (
      context: ActionContext<SalesState, RootState>,
      payload?: string
    ) => {
      context.commit(
        Mutations.SET_LAYBY_CONSUMER_LIST,
        payload ?? context.state.consumer?.pk_consumer
      )
    },
    [Actions.LAYBY_RESUME]: async (
      context: ActionContext<SalesState, RootState>,
      payload: {
        cod_negozio: string
        id_transazione: number
        laybyInfo: LaybyInfo
      }
    ) => {
      const response = await salesApi.apiV1SalesSaleIdGet(
        `${payload.cod_negozio},${payload.id_transazione}`
      )

      await context.dispatch(
        Actions.HYDRATE_SALES_SKUS,
        response.data.data?.attributes?.capi
      )

      context.commit(Mutations.LAYBY_RESUME, response.data.data?.attributes)
      sessionStorage.setItem(
        SessionKeys.LAYBY_RESUME,
        JSON.stringify({
          laybyStatus: context.state.laybyStatus,
          layby: response.data.data?.attributes,
          laybyInfo: payload.laybyInfo,
        })
      )
    },
    [Actions.RESET_LAYBY_RESUME_DATA]: (
      context: ActionContext<SalesState, RootState>
    ) => {
      context.commit(Mutations.RESET_LAYBY_RESUME_DATA)
    },
    [Actions.SET_CAPI_LAYBY_DELETE]: (
      context: ActionContext<SalesState, RootState>
    ) => {
      context.commit(Mutations.SET_CAPI_LAYBY_DELETE)
    },
    [Actions.REMOVE_CAPI_LAYBY_DELETE]: (
      context: ActionContext<SalesState, RootState>
    ) => {
      context.commit(Mutations.REMOVE_CAPI_LAYBY_DELETE)
    },
    [Actions.CLEAR_SALE_CAPI]: (
      context: ActionContext<SalesState, RootState>
    ) => {
      context.commit(Mutations.CLEAR_SALE_CAPI)
      context.commit(Mutations.RESET_INFO_GIACENZA_CAPI)
    },
    [Actions.CHECK_DATI_ANTIRICICLAGGIO]: async (
      context: ActionContext<SalesState, RootState>,
      datiAntiriciclaggio: ApiV1SalesCheckDatiAntiriciclaggioDataAttributes
    ) => {
      const payload = {
        data: {
          type: 'dati_antiriciclaggio',
          attributes: datiAntiriciclaggio,
        },
      }
      const response = await salesApi.apiV1SalesCheckDatiAntiriciclaggioPost(
        payload
      )
      if (response.data.data?.attributes?.status === 'SUCCESS') {
        context.commit(Mutations.CHECK_ANTIRICICLAGGIO_RESULT, true)

        const printResultOk = await context.dispatch(
          Actions.STAMPA_RIEPILOGO_ANTIRICICLAGGIO,
          datiAntiriciclaggio
        )
        if (printResultOk) {
          store.dispatch(
            NotificationsActions.NOTIFY_SUCCESS,
            i18n.global.t('pos_common.success_doc_print')
          )
        }
      } else if (response.data.data?.attributes?.status === 'ERROR') {
        context.commit(Mutations.CHECK_ANTIRICICLAGGIO_RESULT, false)
        store.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          i18n.global.t('pos_common.print_fail')
        )
        context.dispatch(Actions.UPDATE_PAYMENT_DATA, {
          changed: {
            id: 'printErrors',
            value: response.data.data?.attributes?.form_errors,
          },
          paymentKey: 'cash',
        })
      }
    },
    [Actions.STAMPA_RIEPILOGO_ANTIRICICLAGGIO]: async (
      context: ActionContext<SalesState, RootState>,
      datiAntiriciclaggio: ApiV1SalesCheckDatiAntiriciclaggioDataAttributes
    ) => {
      const payload = {
        data: {
          type: 'dati_antiriciclaggio',
          attributes: datiAntiriciclaggio,
        },
      }
      const response =
        await salesApi.apiV1SalesStampaRiepilogoAntiriciclaggioPost(payload)

      if (response.data.data?.attributes?.status === 'SUCCESS') {
        context.dispatch(Actions.UPDATE_PAYMENT_DATA, {
          id: 'hasPrinted',
          value: true,
        })
        return true
      } else if (response.data.data?.attributes?.status === 'ERROR') {
        store.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          i18n.global.t('pos_common.print_fail')
        )
        return false
      }
    },
    [Actions.SET_LAYBY_DEPOSIT_VALID]: (
      context: ActionContext<SalesState, RootState>,
      payload: boolean
    ): void => {
      context.commit(Mutations.SET_LAYBY_DEPOSIT_VALID, payload)
    },
    [Actions.SET_GIFT_RECEIPT]: (
      context: ActionContext<SalesState, RootState>,
      payload: boolean
    ): void => {
      context.commit(Mutations.SET_GIFT_RECEIPT, payload ? 1 : 0)
    },
    [Actions.REMOVE_LAYBY_CURRENT_SALE]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(Mutations.REMOVE_LAYBY_CURRENT_SALE)
    },
    [Actions.SET_USED_CASH]: (
      context: ActionContext<SalesState, RootState>,
      payload: UsedCash
    ): void => {
      context.commit(Mutations.SET_USED_CASH, payload)
    },
    [Actions.CLEAR_USED_CASH]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(Mutations.CLEAR_USED_CASH)
    },
    [Actions.SET_CODICE_MOVIMENTO]: (
      context: ActionContext<SalesState, RootState>,
      payload: TipiVendita | undefined
    ): void => {
      context.commit(Mutations.SET_CODICE_MOVIMENTO, payload)
    },
    [Actions.SET_DATI_STORNO]: (
      context: ActionContext<SalesState, RootState>,
      payload: datiStornoD
    ): void => {
      context.commit(Mutations.SET_DATI_STORNO, payload)
    },
    [Actions.SET_DATI_STORNO_CURRENTSALE]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(
        Mutations.SET_DATI_STORNO_CURRENTSALE,
        context.state.dati_storno
      )
    },
    [Actions.SET_TOTAL_CAPI]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(Mutations.SET_TOTAL_CAPI)
    },
    [Actions.SET_DISABLED_CONFIRM]: (
      context: ActionContext<SalesState, RootState>,
      payload: boolean
    ): void => {
      context.commit(Mutations.SET_DISABLED_CONFIRM, payload)
    },
    [Actions.CHECK_PRODUCTS_STOCK]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(Mutations.CHECK_PRODUCTS_STOCK)
    },
    [Actions.SET_CAN_CHANGE_CART_TYPE]: (
      context: ActionContext<SalesState, RootState>,
      payload: CanChangeCartTypeD
    ): void => {
      context.commit(Mutations.SET_CAN_CHANGE_CART_TYPE, payload)
    },
    [Actions.SET_INFO_GIACENZA_CAPI]: (
      context: ActionContext<SalesState, RootState>,
      payload: InfoGiacenzaCapiD
    ): void => {
      context.commit(Mutations.SET_INFO_GIACENZA_CAPI, payload)
    },
    [Actions.REMOVE_INFO_GIACENZA_CAPI]: (
      context: ActionContext<SalesState, RootState>,
      payload: string
    ): void => {
      context.commit(Mutations.REMOVE_INFO_GIACENZA_CAPI, payload)
    },
    [Actions.RESET_INFO_GIACENZA_CAPI]: (
      context: ActionContext<SalesState, RootState>
    ): void => {
      context.commit(Mutations.RESET_INFO_GIACENZA_CAPI)
    },
    [Actions.STORE_ID_TRANSAZIONE]: (
      context: ActionContext<SalesState, RootState>,
      id: string
    ): void => {
      context.commit(Mutations.STORE_ID_TRANSAZIONE, id)
    },
    [Actions.STORE_CREATION_DATE]: (
      context: ActionContext<SalesState, RootState>,
      date: string
    ): void => {
      context.commit(Mutations.STORE_CREATION_DATE, date)
    },
    [Actions.ADD_COD_COMMESSA](
      context: ActionContext<SalesState, RootState>,
      payload: string
    ): void {
      context.commit(Mutations.ADD_COD_COMMESSA, payload)
    },
  },
  getters: {
    [Getters.GET_RDV]: (state: SalesState) => state.selected_rdv,
    [Getters.GET_CASHIER]: (state: SalesState) => state.selected_cashier,
    [Getters.GET_CONSUMER]: (state: SalesState) => state.consumer,
    [Getters.GET_RDV_ID]: (state: SalesState): string =>
      state.selected_rdv?.venditrice ?? '',
    [Getters.GET_CASHIER_ID]: (state: SalesState): string =>
      state.selected_cashier?.venditrice ?? '',
    [Getters.GET_CONSUMER_ID]: (state: SalesState): string =>
      state.consumer?.pk_consumer ?? '',
    [Getters.GET_ANONYMOUS_ID]: (state: SalesState): string =>
      state.currentSale?.pk_consumer ?? '',
    [Getters.GET_RDV_LABEL]: (state: SalesState): string => {
      if (state.selected_rdv) {
        return `${state.selected_rdv?.venditrice} - ${state.selected_rdv?.descrizione}`
      }
      return ''
    },
    [Getters.GET_CASHIER_LABEL]: (state: SalesState): string => {
      if (state.selected_cashier) {
        return `${state.selected_cashier?.venditrice} - ${state.selected_cashier?.descrizione}`
      }
      return ''
    },
    [Getters.GET_CUSTOMER_LABEL]: (state: SalesState) => {
      const consumerAttributes = state.consumer
      if (!consumerAttributes || !consumerAttributes?.cognome) {
        return
      }
      return `${consumerAttributes?.cognome} ${consumerAttributes?.nome || ''}`
    },
    [Getters.GET_PAYMENTS]: (
      state: SalesState
    ): Array<ApiV1SalesDataAttributesPagamenti> =>
      state.currentSale.pagamenti ?? [],
    [Getters.GET_ONLY_PAYMENTS]: (
      state: SalesState
    ): Array<ApiV1SalesDataAttributesPagamenti> =>
      state.currentSale.pagamenti?.filter(
        (p: ApiV1SalesDataAttributesPagamenti) =>
          p.codice_movimento === TipiMovimenti.CONTABILITA_PAGAMENTO
      ) ?? [],
    [Getters.GET_DISCOUNT]: (
      state: SalesState
    ):
      | ApiV1SalesDataAttributesPagamenti[]
      | ApiV1SalesSaleIdDataAttributesPagamenti[] => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti.filter(
          (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO
        )
      }
      return []
    },
    [Getters.GET_LOYALTY_PROGRAM_DISCOUNT]: (
      state: SalesState
    ):
      | ApiV1SalesDataAttributesPagamenti[]
      | ApiV1SalesSaleIdDataAttributesPagamenti[] => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti.filter(
          (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_PROMOZIONE
        )
      }
      return []
    },
    [Getters.GET_REBATE]: (state: SalesState): number => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti
          .filter(
            (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO
          )
          .map((p) => p.importo_finale ?? 0)
          .reduce((t: number, p: number) => t + p, 0)
      }
      return 0
    },
    [Getters.GET_TAILORING]: (state: SalesState): number => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti
          .filter(
            (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA
          )
          .map((p) => p.importo_finale ?? 0)
          .reduce((t: number, p: number) => t + p, 0)
      }
      return 0
    },
    // TODO: Remove this getters and use GET_TAILORING
    [Getters.GET_TAILORING_NO_EXTRA]: (state: SalesState): number => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti
          .filter(
            (p) =>
              p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA &&
              p.progressivo_capo !== 0
          )
          .map((p) => p.importo_finale ?? 0)
          .reduce((t: number, p: number) => t + p, 0)
      }
      return 0
    },
    [Getters.IS_CASH_AMOUNT_ABOVE_MAXPAYABLE]: (): boolean => {
      const cashAmount = store.getters[SalesGetters.GET_ONLY_PAYMENTS].find(
        (p: ApiV1SalesDataAttributesPagamenti) =>
          p.cod_operazione === 'CONTANTI'
      )?.importo_iniziale

      return (
        cashAmount >= store.getters[ConfigGetters.GET_MAX_PAYABLE_CASH_AMOUNT]
      )
    },
    [Getters.GET_CHECK_ANTIRICICLAGGIO_RESULT]: (
      state: SalesState
    ): boolean => {
      return state.checkAntireciclaggioResult
    },
    [Getters.GET_PRODUCT_TAILORING]: (state: SalesState): number => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti
          .filter(
            (p) =>
              p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA &&
              p.progressivo_capo !== 0
          )
          .map((p) => p.importo_finale ?? 0)
          .reduce((t: number, p: number) => t + p, 0)
      }
      return 0
    },
    [Getters.GET_EXTRA_TAILORING]: (state: SalesState): object => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti.filter(
          (p) =>
            p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA &&
            p.progressivo_capo === 0
        )
      }
      return []
    },
    [Getters.GET_FREE_EXTRA_TAILORING]: (state: SalesState): object => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti.filter(
          (p) =>
            p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO &&
            p.progressivo_capo === 0
        )
      }
      return []
    },
    [Getters.GET_EXTRA_TAILORING_PRICE]: (state: SalesState): number => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti
          .filter(
            (p) =>
              p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA &&
              p.progressivo_capo === 0
          )
          .map((p) => p.importo_finale ?? 0)
          .reduce((t: number, p: number) => t + p, 0)
      }
      return 0
    },
    [Getters.GET_PAYMENTS_TOTAL]: (state: SalesState): number => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti
          .filter(
            (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_PAGAMENTO
          )
          .map((p) => p.importo_iniziale ?? 0)
          .reduce((t: number, p: number) => t + p, 0)
      }
      return 0
    },
    [Getters.GET_TOTAL_AMOUNT_CASHED_IN]: (state: SalesState): number => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti
          .filter(
            (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_PAGAMENTO
          )
          .map((p) =>
            p.importo_iniziale
              ? p.cod_operazione !== PaymentsType.CREDITO_SOSPESO
                ? p.importo_iniziale
                : 0
              : 0
          )
          .reduce((t: number, p: number) => t + p, 0)
      }
      return 0
    },
    [Getters.GET_CAPI]: (state: SalesState): ApiV1SalesDataAttributesCapi[] =>
      state.currentSale.capi ?? [],
    [Getters.GET_CAPI_PRICE]: (state: SalesState): number => {
      if (state.currentSale.capi?.length) {
        const capiPrice = state.currentSale.capi
          .map((c) => c.importo_finale ?? 0)
          .reduce((t: number, c: number) => t + c, 0)

        const alterationsPrice = state.currentSale.pagamenti
          ? state.currentSale.pagamenti
              .filter(
                (p) =>
                  p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA &&
                  p.progressivo_capo !== 0
              )
              .map((p) => p.importo_finale ?? 0)
              .reduce((t: number, p: number) => t + p, 0)
          : 0
        return capiPrice + alterationsPrice
      }
      return 0
    },
    [Getters.GET_TOTAL_PRICE]: (state: SalesState): number => {
      let totalPagamenti = 0
      if (state.currentSale.pagamenti) {
        totalPagamenti = state.currentSale.pagamenti
          .filter((p) => {
            return (
              p.codice_movimento !== TipiMovimenti.CONTABILITA_PAGAMENTO &&
              p.progressivo &&
              store.state.payments.payments_validator[p.progressivo] !== null
            )
          })
          .map((p) => p.importo_finale ?? 0)
          .reduce((t: number, p: number) => t + p, 0)
      }
      return (state.currentSale.importo_iniziale ?? 0) + totalPagamenti
    },
    [Getters.CHECK_FOR_ONE_CASH_PAYMENT]: (state: SalesState): boolean => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti.some(
          (p) => p.cod_operazione === 'CONTANTI'
        )
      }
      return false
    },
    [Getters.CHECK_FOR_ONE_SUSPENDED_CREDIT_PAYMENT]: (state: SalesState) => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti.some(
          (p) => p.cod_operazione === 'CREDITO_SOSPESO'
        )
      }
      return false
    },
    [Getters.GET_CURRENT_SALE]: (
      state: SalesState
    ): ApiV1SalesDataAttributes | ApiV1SalesSaleIdDataAttributes =>
      state.currentSale,
    [Getters.GET_ANONYMOUS]: (state: SalesState): boolean => state.isAnonymous,
    [Getters.GET_SALE_IMPORTO_FINALE]: (state: SalesState): number =>
      state.currentSale.importo_finale ?? 0,
    [Getters.GET_CURRENT_CAPO_TAILORING]: (
      state: SalesState,
      getters: GetterTree<SalesState, RootState>,
      rootState: RootState
    ) => {
      if (state.currentSale.pagamenti) {
        return state.currentSale.pagamenti.filter(
          (p) =>
            p.progressivo_capo === rootState.product.progressivo &&
            (p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
              p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO)
        )
      }
      return []
    },
    [Getters.GET_PRODUCT]:
      (state: SalesState) =>
      (progressivo: number): GetSkuDataAttributes | null =>
        state.product_detail[progressivo] ?? null,
    [Getters.GET_CAPO]:
      (state: SalesState) =>
      (
        progressivo: number
      ):
        | ApiV1SalesDataAttributesCapi
        | ApiV1SalesSaleIdDataAttributesCapi
        | null =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.currentSale.capi?.find((c) => c.progressivo === progressivo) ??
        null,
    [Getters.GET_CAPO_ALTERATION_TOTAL]:
      (state: SalesState) =>
      (progressivo: number): number | undefined => {
        return state.currentSale.pagamenti
          ?.filter(
            (p) =>
              p.progressivo_capo === progressivo &&
              (p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
                p.codice_movimento ===
                  TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO)
          )
          .map((p) => p.importo_finale ?? 0)
          .reduce((t, i) => t + i, 0)
      },
    [Getters.GET_CAPO_ALTERATION]:
      (state: SalesState) =>
      (
        progressivo: number
      ): ApiV1SalesDataAttributesPagamenti[] | undefined => {
        progressivo = progressivo || 0
        return state.currentSale.pagamenti?.filter(
          (p) =>
            p.progressivo_capo === progressivo &&
            (p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
              p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO)
        )
      },
    [Getters.GET_CREDITO_SOSPESO_ENABLED]: (
      state: SalesState,
      getters: GetterTree<SalesState, RootState>,
      rootState: RootState
    ) => {
      const updateDate = parse(
        state.consumer?.data_ultimo_aggiornamento || '',
        'yyyyMMdd',
        new Date()
      )
      const refDate = subDays(
        new Date(),
        parseInt(
          rootState.configs.currentStore?.CONSUMER_OUTDATE_DAYS || '',
          10
        )
      )
      const isBefore = compareAsc(updateDate, refDate) === -1
      const noPhone =
        !state.consumer?.telefono1 &&
        !state.consumer?.telefono2 &&
        !state.consumer?.cellulare1 &&
        !state.consumer?.cellulare2
      const noAddress = !state.consumer?.indirizzo || !state.consumer.localita
      return !isBefore && !noPhone && !noAddress
    },
    [Getters.GET_DOCUMENTO_SPEDIZIONE]: (
      state: SalesState
    ): ShippingDetails | null => {
      if (state.currentSale.dati_documenti) {
        const parsedShippingDetails = JSON.parse(
          state.currentSale.dati_documenti
        )
        return {
          shipping_destination: parsedShippingDetails.shipping_destination,
          shipping_address: parsedShippingDetails.shipping_address,
        }
      }
      return null
    },
    [Getters.GET_CARDS_PAYMENT_IN_RETURNS_VALID]: (): boolean => {
      let areThereValidCardPaymentsInReturns
      const condition = (pagamento) => {
        return (
          pagamento.cod_operazione === PaymentsType.CARTA ||
          pagamento.cod_operazione === PaymentsType.GIFT_CARD_CORPORATE
        )
      }
      const salesPayments =
        store.state.sales?.currentSale?.pagamenti?.filter(condition) ?? []
      const payments =
        store.state.payments?.selectedPayments.filter(condition) ?? []
      const totalP = [...salesPayments, ...payments]
      if (totalP.length > 0) {
        areThereValidCardPaymentsInReturns = totalP.some((payment) => {
          const jsonObj = JSON.parse(payment?.dati_operazione || '{}')
          const esitoCarta = jsonObj?.esito
          const esitoGift = jsonObj?.Result
          return esitoCarta === 'ESITO_POSITIVO' || esitoGift === 'OK'
        })
      } else {
        areThereValidCardPaymentsInReturns = false
      }
      return areThereValidCardPaymentsInReturns
    },
    [Getters.GET_CREDIT_CARD_PAYMENT_VALID]: (state: SalesState): boolean => {
      if (!state.currentSale.pagamenti?.length) {
        return false
      }
      const pay = state.currentSale.pagamenti.find(
        (p) => p.cod_operazione === 'CARTA'
      )
      return (
        !!pay &&
        !!pay.progressivo &&
        !!store.state.payments.payments_validator[pay.progressivo]
      )
    },
    [Getters.GET_GIFT_CARD_PAYMENT_VALID]: (state: SalesState): boolean => {
      if (!state.currentSale.pagamenti?.length) {
        return false
      }
      const pay = state.currentSale.pagamenti.find(
        (p) => p.cod_operazione === PaymentsType.GIFT_CARD_CORPORATE
      )
      return (
        !!pay &&
        typeof pay.progressivo !== 'undefined' &&
        !!store.state.payments.payments_validator[pay.progressivo]
      )
    },
    [Getters.PURCHASE_IS_ABOVE_THRESHOLD]: (state: SalesState): boolean => {
      const getTotalPrice = store.getters[SalesGetters.GET_TOTAL_PRICE]
      const getPaymentsTotal = store.getters[SalesGetters.GET_PAYMENTS_TOTAL]
      const preChange = getTotalPrice - getPaymentsTotal
      const change = preChange < 0 ? Math.abs(preChange) : 0
      const importo = store.getters[SalesGetters.GET_ONLY_PAYMENTS].find(
        (p: ApiV1SalesDataAttributesPagamenti) =>
          p.cod_operazione === 'CONTANTI'
      )?.importo_iniziale
      const cashPayableAmount = store.getters[
        SalesGetters.GET_CONSUMER_SAME_COUNTRY_CONDE
      ]
        ? store.getters[ConfigGetters.GET_MAX_PAYABLE_CASH_AMOUNT]
        : store.getters[ConfigGetters.GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_COUNTRY]

      const currentImport =
        (state.usedCash?.amount ?? 0) + parseFloat(importo) - change
      return currentImport >= cashPayableAmount
    },
    [Getters.GET_CONSUMER_SAME_COUNTRY_CONDE]: (): boolean => {
      const cashForm = store.getters[SalesGetters.GET_PAYMENT_DATA_CASH]
      const getNations = store.getters[GenericGetters.GET_NATIONS]
      const countryCode = store.getters[ConfigGetters.GET_COUNTRY_CODE]
      const getAnonymousConsumer =
        store.getters[ConsumersGetters.GET_ANONYMOUS_CONSUMER_LIST]
      const getAnonymousConsumerId =
        store.getters[SalesGetters.GET_ANONYMOUS_ID]
      const getConsumer = store.getters[SalesGetters.GET_CONSUMER]
      if (store.getters[SalesGetters.GET_ANONYMOUS]) {
        if (cashForm.nazioneDiResidenza) {
          const selectedNationInForm = getNations.find(
            (c: GetCountriesAttributes) =>
              c.nazione_iso === cashForm.nazioneDiResidenza
          )
          return selectedNationInForm?.nazione_iso === countryCode
        }
        if (getAnonymousConsumerId !== AnonymousConsumers.SUPER_ANONYMOUS) {
          const anonymousConsumer = getAnonymousConsumer.find(
            (consumer: any) => consumer.pk_consumer === getAnonymousConsumerId
          )
          if (anonymousConsumer) {
            const selectedNation = getNations.find(
              (nation: GetCountriesAttributes) =>
                nation.nazione_iso === anonymousConsumer.nation
            )
            if (selectedNation) {
              return selectedNation?.nazione_iso === countryCode
            }
          }
        }

        const selectedStoreNation = getNations.find(
          (c: GetCountriesAttributes) => c.nazione_iso === countryCode
        )
        return selectedStoreNation?.nazione_iso === countryCode
      }
      const selectedNationInForm = getNations.find(
        (c: GetCountriesAttributes) =>
          c.nazione_iso === cashForm.nazioneDiResidenza
      )
      if (selectedNationInForm) {
        return selectedNationInForm?.nazione_iso === countryCode
      }
      return countryCode === getConsumer?.nazione_iso
    },
    [Getters.GET_COUPON]: (
      state: SalesState
    ): ApiV1SalesSaleIdDataAttributesPagamenti | undefined => {
      if (!state.currentSale.pagamenti?.length) {
        return undefined
      }
      return (
        state.currentSale.pagamenti.find(
          (p) =>
            p.codice_movimento === TipiMovimenti.CONTABILITA_COUPON &&
            store.state.payments.payments_validator[String(p.progressivo)] !==
              false
        ) ?? undefined
      )
    },
    /**
     * Getter that returns:
     * <ul>
     *     <li>0 if all articles are IN_STORE</li>
     *     <li>1 if mixed</li>
     *     <li>2 if all articles are OUT_STORE (b2e articles)</li>
     * </ul>
     * @param state
     */
    [Getters.GET_SALE_STORE_STATUS]: (state: SalesState): SaleStoreStatus => {
      if (!state.currentSale?.capi?.length) {
        return SaleStoreStatus.IN_STORE
      }
      const outStoreCount =
        state.currentSale?.capi?.filter((c) => {
          if (c.custom_data) {
            const parsedData = JSON.parse(c.custom_data)
            return parsedData?.stock_type === 'OUT_STORE'
          }
          return null
        })?.length || 0
      if (!state.currentSale?.capi?.length) {
        return 0
      }
      if (outStoreCount === state.currentSale?.capi?.length) {
        return SaleStoreStatus.OUT_STORE
      } // Tutta out of store
      if (outStoreCount > 0) {
        return SaleStoreStatus.MIXED
      } // Mista
      return SaleStoreStatus.IN_STORE // tutta in store
    },
    [Getters.GET_CART_VIEW]: (state: SalesState): string => {
      return state.cartView || 'carousel'
    },
    [Getters.GET_DATA_VISIBILITY]: (
      state: SalesState
    ): Statistics | undefined => {
      return <Statistics>state.consumerStatistics
    },
    [Getters.GET_IS_GIFT_CARD_SALE]: (state: SalesState): boolean => {
      return !!state.currentSale?.pagamenti?.find(
        (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_GIFT
      )
    },
    [Getters.GET_SUSPENDED_CREDIT]: (
      state: SalesState
    ): SuspendedCreditForm => {
      return state.suspendedCredit
    },
    [Getters.GET_SALES_HEADER_ICONS]: (
      state: SalesState
    ): GetConsumerExtraInfoDataAttributes => state.headerIcons,
    [Getters.GET_PAYMENT_DATA]: (state: SalesState): PaymentMethodForm => {
      return state.paymentData || ({} as PaymentMethodForm)
    },
    [Getters.GET_PAYMENT_DATA_CASH]: (
      state: SalesState
    ): CashGenericPaymentMethodForm => {
      return state.paymentData.cashData || ({} as CashGenericPaymentMethodForm)
    },
    [Getters.GET_CART_TYPE]: (state: SalesState): CartType[] => {
      return state.cartType
    },
    [Getters.GET_MAX_IMPORT_CHEQUE]: (state: SalesState): number => {
      const { MAX_MONEY_AMOUNT, MAX_MONEY_AMOUNT_EXTRA_COUNTRY } =
        store.getters[ConfigGetters.GET_STORE_CONFIGS]
      return state.consumer?.nazionalita === 'IT'
        ? parseFloat(MAX_MONEY_AMOUNT)
        : parseFloat(MAX_MONEY_AMOUNT_EXTRA_COUNTRY)
    },
    [Getters.GET_SELECTED_CART_TYPE]: (state: SalesState): CartType => {
      return state.selectedCartType
    },
    [Getters.GET_LAYBY_INFO]: (state: SalesState): LaybyInfo => {
      return state.laybyInfo
    },
    [Getters.GET_LAYBY_STATUS]: (state: SalesState): string => {
      return state.laybyStatus
    },
    [Getters.GET_LAYBY_STATUS_EQUAL_TO]:
      (state: SalesState) =>
      (type: LaybyStatusType): boolean =>
        state.laybyStatus === type,
    [Getters.GET_IS_DEPOSIT_FLOW]: (state: SalesState): boolean =>
      state.selectedCartType?.id === LaybyCartTypeID.ACCONTO,
    [Getters.GET_TOTAL_PRICE_DEPOSIT]: (state: SalesState): number => {
      return state.totalPriceDeposit
    },
    [Getters.GET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD]: (
      state: SalesState
    ): number => {
      return state.totalPriceDepositPaymentMethod
    },
    [Getters.GET_LAYBY_MINIMUM_DEPOSIT]: (state: SalesState): number => {
      return state.laybyInfo.acconto_minimo
    },
    [Getters.GET_LAYBY_CONSUMER_LIST]: (
      state: SalesState
    ): LaybyConsumerList[] => {
      return state.laybyConsumerList
    },
    [Getters.GET_LAYBY_RESUME_DATA]: (state: SalesState): LaybyElement => {
      return state.laybyResumeData
    },
    [Getters.GET_IS_GIFT_CARD_SAVE_IN_PROCESS]: (
      state: SalesState
    ): boolean => {
      return state.saveGiftCardInProcess
    },
    [Getters.GET_LAYBY_DEPOSIT_VALID]: (state: SalesState): boolean => {
      return state.laybyDepositValid
    },
    [Getters.GET_IS_GROUP_CONSUMER]: (state: SalesState): boolean => {
      return state.isGroupConsumer
    },
    [Getters.GET_LAYBY_CALC_MINIMUM_DEPOSIT]: (): number => {
      const { LAYBY_MINIMUM, LAYBY_MINIMUM_UNIT } =
        store.getters[ConfigGetters.GET_STORE_CONFIGS]

      const parsedMinimum = parseFloat(LAYBY_MINIMUM ?? 0)
      const laybyMinimumAmount =
        LAYBY_MINIMUM_UNIT === '%'
          ? parseFloat(
              (
                (parsedMinimum / 100) *
                store.getters[SalesGetters.GET_TOTAL_PRICE]
              ).toFixed(2)
            )
          : parsedMinimum
      return laybyMinimumAmount
    },
    [Getters.GET_USED_CASH]: (state: SalesState): UsedCash => state.usedCash,
    [Getters.GET_SUM_OF_DEBTPAYMENTS_VALIDATION_GIFTCARDSALE]: (
      state: SalesState
    ): boolean => {
      const debtPayments = store.getters[SalesGetters.GET_ONLY_PAYMENTS].filter(
        (payment) =>
          payment.cod_operazione === PaymentsType.CREDITO_SOSPESO ||
          payment.cod_operazione === PaymentsType.BUONO_GIFT_CARD ||
          payment.cod_operazione === PaymentsType.GIFT_CARD_CORPORATE
      )
      const debtPaymentsTotal = debtPayments.reduce(
        (totalSum, currentPayment) => {
          return totalSum + currentPayment.importo_finale
        },
        0
      )
      const giftCardSale = state.currentSale.pagamenti?.filter(
        (sale) => sale.cod_operazione === CostiExtraType.GIFT_CARD
      )

      const giftCardSaleTotal = giftCardSale?.reduce(
        (totalSum, currentSale) => {
          return totalSum + (currentSale?.importo_finale ?? 0)
        },
        0
      )
      const saleWithoutDebtPayment =
        store.getters[SalesGetters.GET_CURRENT_SALE].importo_finale -
        giftCardSaleTotal
      if (giftCardSaleTotal === 0) {
        return false
      }
      return debtPaymentsTotal > (saleWithoutDebtPayment ?? 0)
    },
    [Getters.IS_FREE_RETURN]: (state: SalesState): boolean => {
      const returnPagamenti =
        state.currentSale.pagamenti?.some((c) => {
          if (c.dati_operazione && Object.keys(c.dati_operazione).length > 0) {
            const cod_mov = JSON.parse(c.dati_operazione ?? '{}')?.cod_movimento
            return (
              cod_mov === TipiVendita.STORNO || cod_mov === TipiVendita.RESO
            )
          }
        }) || false
      const returnCapi =
        state.currentSale.capi?.some(
          (c) =>
            c.codice_movimento === TipiVendita.STORNO ||
            c.codice_movimento === TipiVendita.RESO
        ) || false
      return returnPagamenti || returnCapi
    },
    [Getters.IS_ALL_FREE_RETURN]: (state: SalesState): boolean => {
      const allowedType = [
        TipiVendita.STORNO,
        TipiVendita.RESO,
        TipiVendita.SCARICO_OMAGGIO,
      ]
      return (
        state.currentSale.capi?.every((c) =>
          allowedType.includes(c.codice_movimento as TipiVendita)
        ) || false
      )
    },
    [Getters.IS_ONLY_TAILORING_ALTERATION_FREE_SALE]: (state: SalesState) => {
      return (
        state.currentSale.pagamenti &&
        state.currentSale.capi?.length === 0 &&
        state.currentSale.pagamenti.filter(
          (p) =>
            p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO
        ).length === state.currentSale.pagamenti.length
      )
    },
    [Getters.IS_ONLY_FREE_SALE]: (state: SalesState) => {
      return (
        state.currentSale.capi?.length &&
        state.currentSale.pagamenti?.length === 0 &&
        state.currentSale.capi.every(
          (p) => p.codice_movimento === TipiVendita.SCARICO_OMAGGIO
        )
      )
    },
    [Getters.IS_OSS_SALE]: (state: SalesState) => {
      // ideally we should get rid of this logic and use a dedicated property of the currentSale (e.g. is_oss_sale) for a more efficient check

      let datiDocumenti =
        JSON.parse(state.currentSale?.dati_documenti || '{}') || {}
      let shippingAddress = datiDocumenti?.shipping_address || {}
      const storeCountryCode = store.getters[ConfigGetters.GET_COUNTRY_CODE]
      const isShippingAddressCountryInEU = (address: ShippingAddress) => {
        const countryIso = address?.country
        const selectedNation = store.getters[GenericGetters.GET_NATIONS].find(
          (nation: GetCountriesAttributes) => nation.nazione_iso === countryIso
        )
        return selectedNation?.flag_ue
      }
      return !!(
        isConfigTruthy('OSS_ENABLED') &&
        !state.isAnonymous &&
        Object.keys(datiDocumenti)?.length > 0 &&
        Object.keys(shippingAddress)?.length > 0 &&
        shippingAddress?.country !== storeCountryCode &&
        isShippingAddressCountryInEU(shippingAddress)
      )
    },
    [Getters.GET_CODICE_MOVIMENTO]: (state: SalesState): string | undefined =>
      state.currentSale.codice_movimento,
    [Getters.GET_DATI_STORNO]: (state: SalesState): datiStornoD =>
      state.dati_storno,
    [Getters.GET_DISABLED_CONFIRM]: (state: SalesState): boolean =>
      state.disabledConfirm,
    [Getters.GET_CAN_CHANGE_CART_TYPE]: (
      state: SalesState
    ): CanChangeCartTypeD => state.canChangeCartType,
    [Getters.GET_ALL_PRODUCT]: (
      state: SalesState
    ): GetSkuDataAttributes | null => state.product_detail ?? null,
    [Getters.GET_INFO_GIACENZA_CAPI]: (state: SalesState): IntoGiacenzaCapiT =>
      state.infoGiacenzaCapi,
    [Getters.GET_QUANTITY_INFO_GIACENZA_CAPI]:
      (state: SalesState): ((sku: string) => number) =>
      (sku: string) =>
        state.currentSale?.capi?.filter((c) => c.sku === sku).length || 0,
  },
}

export default salesStore

export const SalesActions = {
  OPEN_CREDIT_CARD_MODAL: `sales/${Actions.OPEN_CREDIT_CARD_MODAL}`,
  CLOSE_CREDIT_CARD_MODAL: `sales/${Actions.CLOSE_CREDIT_CARD_MODAL}`,
  RESET_STATE: `sales/${Actions.RESET_STATE}`,
  INIT_SALE: `sales/${Actions.INIT_SALE}`,
  CREATE_SALE: `sales/${Actions.CREATE_SALE}`,
  SAVE_SALE: `sales/${Actions.SAVE_SALE}`,
  UPDATE_SALE: `sales/${Actions.UPDATE_SALE}`,
  SUSPEND_SALE: `sales/${Actions.SUSPEND_SALE}`,
  TRY_PAYMENT_CREDIT_CARD: `sales/${Actions.TRY_PAYMENT_CREDIT_CARD}`,
  HYDRATE_SALES: `sales/${Actions.HYDRATE_SALES}`,
  HYDRATE_SALES_SKUS: `sales/${Actions.HYDRATE_SALES_SKUS}`,
  ADD_SKU: `sales/${Actions.ADD_SKU}`,
  ADD_PAYMENT: `sales/${Actions.ADD_PAYMENT}`,
  ADD_PAYMENT_DATA: `sales/${Actions.ADD_PAYMENT_DATA}`,
  EDIT_PAYMENT: `sales/${Actions.EDIT_PAYMENT}`,
  REMOVE_PAYMENT: `sales/${Actions.REMOVE_PAYMENT}`,
  ADD_CAPO: `sales/${Actions.ADD_CAPO}`,
  UPDATE_CAPO: `sales/${Actions.UPDATE_CAPO}`,
  REMOVE_CAPO: `sales/${Actions.REMOVE_CAPO}`,
  SET_PAYMENTS: `sales/${Actions.SET_PAYMENTS}`,
  RESET_PAYMENTS: `sales/${Actions.RESET_PAYMENTS}`,
  RESET_ONLY_PAYMENTS_TYPE: `sales/${Actions.RESET_ONLY_PAYMENTS_TYPE}`,
  RESET_REBATES: `sales/${Actions.RESET_REBATES}`,
  REMOVE_REBATES_VALIDATION: `sales/${Actions.REMOVE_REBATES_VALIDATION}`,
  CONFIRM_REBATES: `sales/${Actions.CONFIRM_REBATES}`,
  CONFIRM_DISCOUNTS: `sales/${Actions.CONFIRM_DISCOUNTS}`,
  CONFIRM_TAILORING_ALTERATIONS: `sales/${Actions.CONFIRM_TAILORING_ALTERATIONS}`,
  DELETE_UNCONFIRMED_EXTRA_SERVICES: `sales/${Actions.DELETE_UNCONFIRMED_EXTRA_SERVICES}`,
  RESET_DISCOUNTS: `sales/${Actions.RESET_DISCOUNTS}`,
  REMOVE_DISCOUNTS_VALIDATION: `sales/${Actions.REMOVE_DISCOUNTS_VALIDATION}`,
  SET_DOCUMENTO_DATI: `sales/${Actions.SET_DOCUMENTO_DATI}`,
  SET_DOCUMENTO_SPEDIZIONE: `sales/${Actions.SET_DOCUMENTO_SPEDIZIONE}`,
  RESET_DOCUMENTO_DATI: `sales/${Actions.RESET_DOCUMENTO_DATI}`,
  SET_DOCUMENTO_CODICE: `sales/${Actions.SET_DOCUMENTO_CODICE}`,
  RESET_DOCUMENTO_CODICE: `sales/${Actions.RESET_DOCUMENTO_CODICE}`,
  SET_NOTA: `sales/${Actions.SET_NOTA}`,
  RESET_NOTA: `sales/${Actions.RESET_NOTA}`,
  SET_CONSUMER: `sales/${Actions.SET_CONSUMER}`,
  SET_CASHIER: `sales/${Actions.SET_CASHIER}`,
  SET_MONEY_CHANGE: `sales/${Actions.SET_MONEY_CHANGE}`,
  SET_ANONYMOUS: `sales/${Actions.SET_ANONYMOUS}`,
  SET_ANONYMOUS_CONSUMER: `sales/${Actions.SET_ANONYMOUS_CONSUMER}`,
  SET_RDV: `sales/${Actions.SET_RDV}`,
  SET_DATA_VISIBILITY: `sales/${Actions.SET_DATA_VISIBILITY}`,
  ACTIVATE_GIFT_CARDS: `sales/${Actions.ACTIVATE_GIFT_CARDS}`,
  PAY_GIFT_CARD: `sales/${Actions.PAY_GIFT_CARD}`,

  UPDATE_PAYMENT_DATA: `sales/${Actions.UPDATE_PAYMENT_DATA}`,
  UPDATE_FULL_PAYMENT_DATA: `sales/${Actions.UPDATE_FULL_PAYMENT_DATA}`,

  CLOSE_SALE: `sales/${Actions.CLOSE_SALE}`,
  SET_CART_TYPE: `sales/${Actions.SET_CART_TYPE}`,
  SET_SELECTED_CART_TYPE: `sales/${Actions.SET_SELECTED_CART_TYPE}`,
  SET_LAYBY_INFO: `sales/${Actions.SET_LAYBY_INFO}`,
  SET_LAYBY_STATUS: `sales/${Actions.SET_LAYBY_STATUS}`,
  SET_TOTAL_PRICE_DEPOSIT: `sales/${Actions.SET_TOTAL_PRICE_DEPOSIT}`,
  SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD: `sales/${Actions.SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD}`,
  SET_STORE_SALE_LAYBY: `sales/${Actions.SET_STORE_SALE_LAYBY}`,
  RESET_LAYBY: `sales/${Actions.RESET_LAYBY}`,
  RESET_CART_TYPE: `sales/${Actions.RESET_CART_TYPE}`,
  RESET_LAYBY_DATA: `sales/${Actions.RESET_LAYBY_DATA}`,
  SET_LAYBY_CONSUMER_LIST: `sales/${Actions.SET_LAYBY_CONSUMER_LIST}`,
  LAYBY_RESUME: `sales/${Actions.LAYBY_RESUME}`,
  RESET_LAYBY_RESUME_DATA: `sales/${Actions.RESET_LAYBY_RESUME_DATA}`,
  SET_CAPI_LAYBY_DELETE: `sales/${Actions.SET_CAPI_LAYBY_DELETE}`,
  REMOVE_CAPI_LAYBY_DELETE: `sales/${Actions.REMOVE_CAPI_LAYBY_DELETE}`,
  CLEAR_SALE_CAPI: `sales/${Actions.CLEAR_SALE_CAPI}`,
  RESET_ANONYMOUS_CONSUMER: `sales/${Actions.RESET_ANONYMOUS_CONSUMER}`,
  SET_LAYBY_DEPOSIT_VALID: `sales/${Actions.SET_LAYBY_DEPOSIT_VALID}`,
  SET_GIFT_RECEIPT: `sales/${Actions.SET_GIFT_RECEIPT}`,
  REMOVE_LAYBY_CURRENT_SALE: `sales/${Actions.REMOVE_LAYBY_CURRENT_SALE}`,
  SET_CODICE_MOVIMENTO: `sales/${Actions.SET_CODICE_MOVIMENTO}`,
  SET_DATI_STORNO: `sales/${Actions.SET_DATI_STORNO}`,
  SET_DATI_STORNO_CURRENTSALE: `sales/${Actions.SET_DATI_STORNO_CURRENTSALE}`,
  SET_DISABLED_CONFIRM: `sales/${Actions.SET_DISABLED_CONFIRM}`,
  CHECK_PRODUCTS_STOCK: `sales/${Actions.CHECK_PRODUCTS_STOCK}`,
  SET_CAN_CHANGE_CART_TYPE: `sales/${Actions.SET_CAN_CHANGE_CART_TYPE}`,
  SET_INFO_GIACENZA_CAPI: `sales/${Actions.SET_INFO_GIACENZA_CAPI}`,
  REMOVE_INFO_GIACENZA_CAPI: `sales/${Actions.REMOVE_INFO_GIACENZA_CAPI}`,
  RESET_INFO_GIACENZA_CAPI: `sales/${Actions.RESET_INFO_GIACENZA_CAPI}`,
  STORE_ID_TRANSAZIONE: `sales/${Actions.STORE_ID_TRANSAZIONE}`,
  STORE_CREATION_DATE: `sales/${Actions.STORE_CREATION_DATE}`,
  ADD_COD_COMMESSA: `sales/${Actions.ADD_COD_COMMESSA}`,
  // old action
  UPDATE_TAILORING: `sales/${Actions.UPDATE_TAILORING}`,
  SET_INITIAL_PRICE: `sales/${Actions.SET_INITIAL_PRICE}`,
  SET_FINAL_PRICE: `sales/${Actions.SET_FINAL_PRICE}`,
  SET_TOTAL_PAYMENTS: `sales/${Actions.SET_TOTAL_PAYMENTS}`,
  UPDATE_AVAILABLE_PAYMENTS_PROGRESSIVE: `sales/${Actions.UPDATE_AVAILABLE_PAYMENTS_PROGRESSIVE}`,
  UPDATE_CURRENT_CAPO_ALTERATIONS: `sales/${Actions.UPDATE_CURRENT_CAPO_ALTERATIONS}`,
  CHECK_SALES_TRACE: `sales/${Actions.CHECK_SALES_TRACE}`,
  COMPUTE_TOTALS: `sales/${Actions.COMPUTE_TOTALS}`,
  UPDATE_PRODUCT_DETAIL: `sales/${Actions.UPDATE_PRODUCT_DETAIL}`,
  UPDATE_PAYMENT_VALIDATION: `sales/${Actions.UPDATE_PAYMENT_VALIDATION}`,
  CHECK_COUPON: `sales/${Actions.CHECK_COUPON}`,
  CONFIRM_COUPON: `sales/${Actions.CONFIRM_COUPON}`,
  DELETE_COUPON: `sales/${Actions.DELETE_COUPON}`,
  VALIDATE_COUPON: `sales/${Actions.VALIDATE_COUPON}`,
  STORE_PAYMENT_AMOUNT: `sales/${Actions.STORE_PAYMENT_AMOUNT}`,
  UPDATE_SUSPENDED_CREDIT: `sales/${Actions.UPDATE_SUSPENDED_CREDIT}`,
  FETCH_SALES_HEADER_ICONS: `sales/${Actions.FETCH_SALES_HEADER_ICONS}`,
  CHECK_DATI_ANTIRICICLAGGIO: `sales/${Actions.CHECK_DATI_ANTIRICICLAGGIO}`,
  STAMPA_RIEPILOGO_ANTIRICICLAGGIO: `sales/${Actions.STAMPA_RIEPILOGO_ANTIRICICLAGGIO}`,
  SET_TOTAL_CAPI: `sales/${Actions.SET_TOTAL_CAPI}`,
  CHECK_TRANSACTION: `sales/${Actions.CHECK_TRANSACTION}`,
  RESET_DATI_STORNO: `sales/${Actions.RESET_DATI_STORNO}`,
  SET_SALE_DATI_PROMO: `sales/${Actions.SET_SALE_DATI_PROMO}`,
}

export const SalesGetters = {
  GET_RDV: `sales/${Getters.GET_RDV}`,
  GET_CASHIER: `sales/${Getters.GET_CASHIER}`,
  GET_CONSUMER: `sales/${Getters.GET_CONSUMER}`,
  GET_RDV_ID: `sales/${Getters.GET_RDV_ID}`,
  GET_CASHIER_ID: `sales/${Getters.GET_CASHIER_ID}`,
  GET_ANONYMOUS_ID: `sales/${Getters.GET_ANONYMOUS_ID}`,
  GET_CONSUMER_ID: `sales/${Getters.GET_CONSUMER_ID}`,
  GET_RDV_LABEL: `sales/${Getters.GET_RDV_LABEL}`,
  GET_CASHIER_LABEL: `sales/${Getters.GET_CASHIER_LABEL}`,
  GET_CUSTOMER_LABEL: `sales/${Getters.GET_CUSTOMER_LABEL}`,
  GET_PAYMENTS: `sales/${Getters.GET_PAYMENTS}`,
  GET_ONLY_PAYMENTS: `sales/${Getters.GET_ONLY_PAYMENTS}`,
  GET_PAYMENTS_TOTAL: `sales/${Getters.GET_PAYMENTS_TOTAL}`,
  GET_TOTAL_AMOUNT_CASHED_IN: `sales/${Getters.GET_TOTAL_AMOUNT_CASHED_IN}`,
  GET_DISCOUNT: `sales/${Getters.GET_DISCOUNT}`,
  GET_DISCOUNT_DATA: `sales/${Getters.GET_DISCOUNT_DATA}`,
  GET_LOYALTY_PROGRAM_DISCOUNT: `sales/${Getters.GET_LOYALTY_PROGRAM_DISCOUNT}`,
  GET_DOCUMENTO_SPEDIZIONE: `sales/${Getters.GET_DOCUMENTO_SPEDIZIONE}`,
  GET_REBATE: `sales/${Getters.GET_REBATE}`,
  GET_TAILORING: `sales/${Getters.GET_TAILORING}`,
  GET_PRODUCT_TAILORING: `sales/${Getters.GET_PRODUCT_TAILORING}`,
  GET_EXTRA_TAILORING: `sales/${Getters.GET_EXTRA_TAILORING}`,
  GET_FREE_EXTRA_TAILORING: `sales/${Getters.GET_FREE_EXTRA_TAILORING}`,
  GET_EXTRA_TAILORING_PRICE: `sales/${Getters.GET_EXTRA_TAILORING_PRICE}`,
  GET_TOTAL_PRICE: `sales/${Getters.GET_TOTAL_PRICE}`,
  GET_CAPI_PRICE: `sales/${Getters.GET_CAPI_PRICE}`,
  GET_CAPI: `sales/${Getters.GET_CAPI}`,
  GET_CAPO: `sales/${Getters.GET_CAPO}`,
  CHECK_FOR_ONE_CASH_PAYMENT: `sales/${Getters.CHECK_FOR_ONE_CASH_PAYMENT}`,
  GET_CURRENT_SALE: `sales/${Getters.GET_CURRENT_SALE}`,
  GET_ANONYMOUS: `sales/${Getters.GET_ANONYMOUS}`,
  GET_CURRENT_CAPO_TAILORING: `sales/${Getters.GET_CURRENT_CAPO_TAILORING}`,
  GET_PRODUCT: `sales/${Getters.GET_PRODUCT}`,
  GET_CREDITO_SOSPESO_ENABLED: `sales/${Getters.GET_CREDITO_SOSPESO_ENABLED}`,
  GET_CAPO_ALTERATION: `sales/${Getters.GET_CAPO_ALTERATION}`,
  GET_CAPO_ALTERATION_TOTAL: `sales/${Getters.GET_CAPO_ALTERATION_TOTAL}`,
  GET_SALE_IMPORTO_FINALE: `sales/${Getters.GET_SALE_IMPORTO_FINALE}`,
  GET_CREDIT_CARD_PAYMENT_VALID: `sales/${Getters.GET_CREDIT_CARD_PAYMENT_VALID}`,
  GET_CARDS_PAYMENT_IN_RETURNS_VALID: `sales/${Getters.GET_CARDS_PAYMENT_IN_RETURNS_VALID}`,
  GET_GIFT_CARD_PAYMENT_VALID: `sales/${Getters.GET_GIFT_CARD_PAYMENT_VALID}`,
  GET_COUPON: `sales/${Getters.GET_COUPON}`,
  GET_SALE_STORE_STATUS: `sales/${Getters.GET_SALE_STORE_STATUS}`,
  GET_CART_VIEW: `sales/${Getters.GET_CART_VIEW}`,
  GET_DATA_VISIBILITY: `sales/${Getters.GET_DATA_VISIBILITY}`,
  GET_IS_GIFT_CARD_SAVE_IN_PROCESS: `sales/${Getters.GET_IS_GIFT_CARD_SAVE_IN_PROCESS}`,
  GET_IS_GIFT_CARD_SALE: `sales/${Getters.GET_IS_GIFT_CARD_SALE}`,
  GET_SUSPENDED_CREDIT: `sales/${Getters.GET_SUSPENDED_CREDIT}`,
  CHECK_FOR_ONE_SUSPENDED_CREDIT_PAYMENT: `sales/${Getters.CHECK_FOR_ONE_SUSPENDED_CREDIT_PAYMENT}`,
  GET_SALES_HEADER_ICONS: `sales/${Getters.GET_SALES_HEADER_ICONS}`,
  GET_PAYMENT_DATA: `sales/${Getters.GET_PAYMENT_DATA}`,
  GET_PAYMENT_DATA_CASH: `sales/${Getters.GET_PAYMENT_DATA_CASH}`,
  GET_CART_TYPE: `sales/${Getters.GET_CART_TYPE}`,
  GET_MAX_IMPORT_CHEQUE: `sales/${Getters.GET_MAX_IMPORT_CHEQUE}`,
  GET_SELECTED_CART_TYPE: `sales/${Getters.GET_SELECTED_CART_TYPE}`,
  GET_LAYBY_INFO: `sales/${Getters.GET_LAYBY_INFO}`,
  GET_LAYBY_STATUS: `sales/${Getters.GET_LAYBY_STATUS}`,
  GET_LAYBY_STATUS_EQUAL_TO: `sales/${Getters.GET_LAYBY_STATUS_EQUAL_TO}`,
  GET_IS_DEPOSIT_FLOW: `sales/${Getters.GET_IS_DEPOSIT_FLOW}`,
  GET_TOTAL_PRICE_DEPOSIT: `sales/${Getters.GET_TOTAL_PRICE_DEPOSIT}`,
  GET_LAYBY_MINIMUM_DEPOSIT: `sales/${Getters.GET_TOTAL_PRICE_DEPOSIT}`,
  GET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD: `sales/${Getters.GET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD}`,
  GET_LAYBY_CONSUMER_LIST: `sales/${Getters.GET_LAYBY_CONSUMER_LIST}`,
  GET_LAYBY_RESUME_DATA: `sales/${Getters.GET_LAYBY_RESUME_DATA}`,
  GET_LAYBY_DEPOSIT_VALID: `sales/${Getters.GET_LAYBY_DEPOSIT_VALID}`,
  GET_IS_GROUP_CONSUMER: `sales/${Getters.GET_IS_GROUP_CONSUMER}`,
  GET_LAYBY_CALC_MINIMUM_DEPOSIT: `sales/${Getters.GET_LAYBY_CALC_MINIMUM_DEPOSIT}`,
  GET_USED_CASH: `sales/${Getters.GET_USED_CASH}`,
  GET_SUM_OF_DEBTPAYMENTS_VALIDATION_GIFTCARDSALE: `sales/${Getters.GET_SUM_OF_DEBTPAYMENTS_VALIDATION_GIFTCARDSALE}`,
  IS_FREE_RETURN: `sales/${Getters.IS_FREE_RETURN}`,
  IS_ALL_FREE_RETURN: `sales/${Getters.IS_ALL_FREE_RETURN}`,
  GET_CODICE_MOVIMENTO: `sales/${Getters.GET_CODICE_MOVIMENTO}`,
  GET_DATI_STORNO: `sales/${Getters.GET_DATI_STORNO}`,
  GET_DISABLED_CONFIRM: `sales/${Getters.GET_DISABLED_CONFIRM}`,
  GET_CAN_CHANGE_CART_TYPE: `sales/${Getters.GET_CAN_CHANGE_CART_TYPE}`,
  GET_ALL_PRODUCT: `sales/${Getters.GET_ALL_PRODUCT}`,
  IS_CASH_AMOUNT_ABOVE_MAXPAYABLE: `sales/${Getters.IS_CASH_AMOUNT_ABOVE_MAXPAYABLE}`,
  GET_CHECK_ANTIRICICLAGGIO_RESULT: `sales/${Getters.GET_CHECK_ANTIRICICLAGGIO_RESULT}`,
  GET_INFO_GIACENZA_CAPI: `sales/${Getters.GET_INFO_GIACENZA_CAPI}`,
  GET_QUANTITY_INFO_GIACENZA_CAPI: `sales/${Getters.GET_QUANTITY_INFO_GIACENZA_CAPI}`,
  IS_ONLY_TAILORING_ALTERATION_FREE_SALE: `sales/${Getters.IS_ONLY_TAILORING_ALTERATION_FREE_SALE}`,
  IS_ONLY_FREE_SALE: `sales/${Getters.IS_ONLY_FREE_SALE}`,
  IS_OSS_SALE: `sales/${Getters.IS_OSS_SALE}`,
  GET_CONSUMER_SAME_COUNTRY_CONDE: `sales/${Getters.GET_CONSUMER_SAME_COUNTRY_CONDE}`,
  PURCHASE_IS_ABOVE_THRESHOLD: `sales/${Getters.PURCHASE_IS_ABOVE_THRESHOLD}`,
}

export const SalesMutations = {
  SET_MODAL_CREDIT_CARD_INSTANCE: `sales/${Mutations.SET_MODAL_CREDIT_CARD_INSTANCE}`,
  CLEAR_CASHIER: `sales/${Mutations.CLEAR_CASHIER}`,
  CLEAR_CONSUMER: `sales/${Mutations.CLEAR_CONSUMER}`,
  CLEAR_ANONYMOUS_FLAG: `sales/${Mutations.CLEAR_ANONYMOUS_FLAG}`,
  CLEAR_RDV: `sales/${Mutations.CLEAR_RDV}`,
  UPDATE_PRODUCT_DETAIL: `sales/${Mutations.UPDATE_PRODUCT_DETAIL}`,
  STORE_CART_VIEW: `sales/${Mutations.STORE_CART_VIEW}`,
  STORE_PRODUCTS: `sales/${Mutations.STORE_PRODUCTS}`,
  STORE_SALE_PAGAMENTI: `sales/${Mutations.STORE_SALE_PAGAMENTI}`,
}
